export const GetLangloveDisabled = (value: string) => {
    let status: boolean = false;
    let access: any[] = [];
    const langLove = localStorage.getItem('prelanglove1')
    switch (langLove){
        case 'Одобрение':{  // Одобрение
            access = ["Прикосновения", "Время", "Помощь", "Подарки"]; // Прикосновения, Время, Помощь, Подарки
        }break;
        case 'Время':{  // Время
            access = ["Прикосновения", "Помощь", "Подарки"]; // Прикосновения, Помощь, Подарки
        }break;
        case 'Помощь':{  // Помощь
            access = ["Прикосновения", "Подарки"]; // Прикосновения, Подарки
        }break;
        case 'Подарки':{  // Подарки
            access = ["Прикосновения"]; // Прикосновения
        }break;
        case 'Прикосновения':
        default: {
            access = [];
        } break;
    }
    return !access.includes(value);
}