import { FC, useEffect, useState } from "react";
import CardMedia from '@mui/material/CardMedia';
import { Box, Button, Card, CardActionArea, Grid, Dialog, DialogActions, DialogContent, Modal } from '@mui/material';
//import StarIcon from '@mui/icons-material/Star';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import { useSubmitDataMutation } from "../../services/goroskop";
import User from "./user";
import { Data } from "../../types/data";
import { useParams } from "react-router-dom";
import * as React from "react";
import {
    printCompatible,
    getImagesSrc,
    printRegionLabel,
    printLock,
    printLeftBottom,
} from "./constituents/cabinetInfo"
import Alert from "@mui/material/Alert";
import useWhoIs from "../../hooks/useWhoIs";
import {PrintFooterAstrodating} from "./constituents/printFooter";

const Favorites: FC = () => {

    const [submitData, { data, error, isLoading }] = useSubmitDataMutation()
    //const [submitDataProfile, profile] = useSubmitDataMutation()

    let { user_id } = useParams()

    useEffect(() => {
        let fd = new FormData()
        fd.append('fav', user_id ?? '')

        submitData({ name: 'cabinet/get_favorites.php', data: fd })

        let fdProfile = new FormData()
        fdProfile.append('user_id', user_id ? user_id : '')

        //submitDataProfile({ name: 'profile.php', data: fd })

    }, [])

    const submitEmail = (name: string) => {
        let fd = new FormData()
        fd.append('mailBody', `<h1> ${name} </h1>` ?? '<span> smth go wrong </span>')

        submitData({ name: 'phpMailer.php', data: fd })
    }

    const [submitLikedPerson, LikedPerson] = useSubmitDataMutation()
    const [user, setUser] = useState<Data>();
    const [open, setOpen] = useState(false);
    const whois = useWhoIs();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const addLikedPerson = (likedPersonID: string, callback: any) => {
        let fd = new FormData()
        fd.append('id', likedPersonID)
        submitLikedPerson({ name: 'cabinet/favorites.php', data: fd }).then((answer:any) => {
            if(answer.data){
                try {
                    callback(answer.data);
                }catch (e) {}
            }
        })
    }

    const printCard = (elem:Data) => {
        return (
            <Grid item xs={2} sm={3} md={3} lg={3} key={elem.id}>
                <Card>
                    <CardActionArea>
                        <FavoriteTwoToneIcon
                            // sx={elem.vip !== '0' ? { position: 'absolute', top: '2%', right: '2%', color: 'orange' } : { position: 'absolute', top: '2%', right: '2%'}}
                            sx={{
                                zIndex: 100,
                                position: 'absolute',
                                padding: '3px',
                                margin: '3px',
                                right: 0,
                                top: 0,
                                color: elem.is_favorite == "1" ? 'orange' : 'white',
                                cursor: 'pointer',
                                backgroundColor: 'rgba(128,128,128,0.5)',
                                fontSize: '32px',
                                borderRadius: '20px'
                            }}
                            onClick={(e) => {
                                var targetEvent = e.currentTarget;
                                addLikedPerson(elem.id, function(data: any){
                                    if(data.status && data.status == true) {
                                        try {
                                            if(data.added == true){
                                                targetEvent.style.color = 'orange';
                                            }else{
                                                targetEvent.style.color = 'white';
                                            }
                                        }catch (e) {}
                                    }
                                });
                            }}
                        />
                        <CardMedia
                            onClick={() => {handleOpen(); setUser(elem)}}
                            component="img"
                            loading="lazy"
                            height="140"
                            image={getImagesSrc(elem)}
                            alt="Фото профиля"
                            data-id={elem.id}
                            data-vip={elem.vip}
                            sx={{ '&': { objectFit: 'cover', backgroundSize: '100% auto' }, minHeight: '28vh'}}
                        />
                        {printCompatible(elem.compatible)}
                        {printRegionLabel(elem.city)}
                        {printLock(elem.vip, elem.lock, elem.hide_btn)}
                        {elem.user_OutNum && elem.user_OutNum != '' ? printLeftBottom(elem.user_OutNum, elem.id) : printLeftBottom('id'+elem.id, elem.id)}
                    </CardActionArea>
                </Card>
            </Grid>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{flex:'1', minHeight: 'calc(100vh - 320px)'}}>
                {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
                    <Alert
                        severity="success"
                        sx={{ margin: '15px 20px 20px 15px' }}
                    >Избранное</Alert>
                <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 9, md: 12, lg: 16}} sx={{justifyContent: 'center'}}>
                {/*user ?
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={'body'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent  >
                        <User userData={user} />
                    </DialogContent>
                    <DialogActions>
                        {/ * <Button onClick={handleClose}>Cancel</Button> * /}
                        <Button onClick={handleClose}>Закрыть</Button>
                    </DialogActions>
                </Dialog> : <T>Избранное:</T>*/}

                {(data && data.length > 0 && typeof data !== 'string') ? data.map(elem => {
                    return printCard(elem)
                }) : <Alert
                    severity="info"
                    sx={{ margin: '15px 20px 20px 15px', position: 'relative', padding: '10px 52px 10px 16px' }}
                >Вы пока что никого не добавили в избранное, но это очень легко сделать - просто нажмите сердечко!
                    <FavoriteTwoToneIcon
                        // sx={elem.vip !== '0' ? { position: 'absolute', top: '2%', right: '2%', color: 'orange' } : { position: 'absolute', top: '2%', right: '2%'}}
                        sx={{
                            zIndex: 100,
                            position: 'absolute',
                            padding: '3px',
                            margin: '3px',
                            right: '6px',
                            top: '5px',
                            color: 'white',
                            backgroundColor: 'rgba(128,128,128,0.5)',
                            fontSize: '32px',
                            borderRadius: '20px',
                        }}
                    />
                </Alert>}
                </Grid>
                {user ?
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll={'body'}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogContent  >
                            <User userData={user} />
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <Button onClick={handleClose}>Закрыть</Button>
                        </DialogActions>
                    </Dialog>
                    :
                    null
                }
            </Box>
            { PrintFooterAstrodating() }
        </Box>
    )
}

export default Favorites
