import { Button } from "@mui/material"
import { useSnackbar } from "notistack"
import { FC } from "react"
import { useAppDispatch } from "../../hooks/hooks"
import { useGetApiQuery, useSubmitDataMutation } from "../../services/goroskop"
import T from "../quiz/OnlyText"
import EnhancedTable from "./constituents/Table"
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { getCookie } from '../../functions/Cookie';

const Line5: FC = () => {

    // const { path, date } = useAppSelector(state => state.adminSlice)
    const dispastch = useAppDispatch()

    const { data, error, isLoading } = useGetApiQuery('line5_oo.php')
    const [submitData, { }] = useSubmitDataMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const sendExport = (id?: string, name?: any) => {

        let fD = new FormData();

        id && fD.append('val', id)

        switch (typeof name) {
            case 'object':
                fD.append('obj', name)
                break;
            case 'string':
                fD.append('export', name)
                break;
        }
        submitData({ name: 'export.php', data: fD })

        enqueueSnackbar('Данные успешно обновлены', {
            variant: 'success',
        })
    }

    const role = String(getCookie('role'));

    function printExport(role: string){
        if(role == 'ok_admin') {
            return  <>
                        <T>Экспортировать таблицу с 1 по 9 модератора:</T>
                        <Button variant="contained" size="large" onClick={() =>
                            sendExport(undefined, 'line2')}>
                            Экспортировать
                        </Button>;
                    </>
        }
    }



    // let newDate = date ?
    //     data?.filter(obj => obj['next_contact_date'] && +new Date(obj['next_contact_date']) <= +new Date(date))
    //     : false
    return(
        <>
            {error && <h1>oops, er: {error}</h1>}
            {/* <TextField
                id="date-picker"
                label='Выберите дату'
                type="date"
                sx={{ width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                value={date}
                onChange={(e) => dispastch(restrictDate(e.target.value))}
            /> */}
            {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            {data && (
                <>
                    <Toolbar sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 },}}>
                        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">{'Главный мод ДОК'}{' '}
                        {role == 'ok_admin' ?
                            <Link to={`../profile/6785`}><EditIcon /></Link>
                        : null }
                        </Typography>
                    </Toolbar>
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '61')} nameOfTable='Модератор ОО 1' modInfo={data?.filter(obj => obj['id'] === '6823')} exId={6823} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '62')} nameOfTable='Модератор ОО 2' modInfo={data?.filter(obj => obj['id'] === '6824')} exId={6824} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '63')} nameOfTable='Модератор ОО 3' modInfo={data?.filter(obj => obj['id'] === '6825')} exId={6825} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '64')} nameOfTable='Модератор ОО 4' modInfo={data?.filter(obj => obj['id'] === '6826')} exId={6826} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '65')} nameOfTable='Модератор ОО 5' modInfo={data?.filter(obj => obj['id'] === '6827')} exId={6827} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '66')} nameOfTable='Модератор ОО 6' modInfo={data?.filter(obj => obj['id'] === '6828')} exId={6828} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '67')} nameOfTable='Модератор ОО 7' modInfo={data?.filter(obj => obj['id'] === '6829')} exId={6829} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '68')} nameOfTable='Модератор ОО 8' modInfo={data?.filter(obj => obj['id'] === '6830')} exId={6830} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '69')} nameOfTable='Модератор ОО 9' modInfo={data?.filter(obj => obj['id'] === '6831')} exId={6831} />
                </>
            )}

            {printExport(role)}
        </>
    )
}

export default Line5