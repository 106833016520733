import { FC, useCallback } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { useGetApiQuery } from "../../../services/goroskop";
import EnhancedTable from "./../constituents/Table";

const AdminArchive : FC = () => {

    const {path, name} = useAppSelector(state => state.adminSlice)

    const { data, error, isLoading, currentData } = useGetApiQuery('archive.php')

    let newDate = data
    //  Ну я ниче лучше не придумал


    if (name === 'Активный поиск') {
        newDate = data?.filter(elem => elem.vip == '1')
    } else if (name === 'Пассивный поиск') {
        newDate = data?.filter(elem => elem.vip == '0')
    }

    const MemoizedTable = useCallback(() =>
            <>
                {data && (
                    <>
                        <EnhancedTable data={newDate ? newDate : data} nameOfTable={name} />
                    </>
                )}
            </>
        , [currentData])



    return(
        <>
            {error && <h1>oops, er: {error}</h1>}
            {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            <MemoizedTable />
        </>
    )
}



export default AdminArchive