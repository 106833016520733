import { TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

const validate = (value: string) => {
    const matches = value.match(
        /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    );
    if(matches) {
        return matches?.length > 0 || '';
    }else{
        return '';
    }
};

const allowOnlyNumber = (value: string) => {
    console.log('allowOnlyNumber',value);
    return value.replace(/[^0-9]/g, '')
}


const FormInputNumber = ({ name, control, setValue, label, rules, sx }: FormInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            //rules={{ validate }}
            rules={{} && rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
            }) => (
                <TextField
                    /*InputProps={{
                        style: {
                            paddingBottom: "10px"
                        }
                    }}*/
                    id={`input-${name}`}
                    helperText={error ? error.message : null}
                    sx={sx}
                    size="small"
                    error={!!error}
                    onChange={onChange}
                    value={value ?? ''}
                    fullWidth
                    label={label}
                    variant="outlined"
                    type="number"
                />
            )}
        />
    );
};
export default FormInputNumber
