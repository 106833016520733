import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {stringify} from "querystring";

const responsive = {
    0: { items: 1 }
};

const rememberDataIndex = (dataId: string, slideIndex: number) => {
    if(slideIndex > 0) {
        localStorage.setItem('slide_index', String(slideIndex));
    }
}
const rememberDataId = (dataId: string, get: boolean) => {
    if(!(dataId == '0')){
        if(get) {
            let id = localStorage.getItem('slider_data');
            if(id == dataId) {
                let slideId: number = 0;
                let slideTmp = localStorage.getItem('slide_index');
                if(slideTmp !== null) {
                    slideId = parseInt(slideTmp);
                }
                if(slideId >= 0) {
                    return slideId;
                }else{
                    return 0;
                }
            }
        }else{
            let dataIdTmp = localStorage.getItem('slider_data');
            if(dataIdTmp !== dataId){
                localStorage.setItem('slide_index', '0')
            }
            localStorage.setItem('slider_data', String(dataId))
        }
    }
    return 0
}

const Carousel = ({items, defIndex, dataId}: {items: any[], defIndex: string, dataId: string}) => (
        <AliceCarousel
            mouseTracking
            activeIndex={defIndex && (rememberDataId(dataId, false) >= 0) ? rememberDataId(dataId, true) : 0}
            onSlideChanged={(e) => {
                rememberDataIndex(dataId, e.item)
            }}
            items={items}
            responsive={responsive}
            controlsStrategy="alternate"
            autoPlay={false}
            infinite={false}
            disableDotsControls={items.length <= 1 ? true : false}
            disableButtonsControls={items.length <= 1 ? true : false}
            renderPrevButton={() => {
                return <NavigateBeforeIcon sx={{color: '#6e7ebc', padding: '0 20px 0 0'}} />
            }}
            renderNextButton={() => {
                return <NavigateNextIcon sx={{color: '#6e7ebc', padding: '0 0 0 20px'}} />
            }}
        />
);

export default Carousel