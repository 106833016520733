import { FormInputProps } from "../../types/formInputProps"
import FormInputDropdown from "./form-component/FormInputDropDown"

const CfgGenderList = ({ name, control, setValue }: Omit<FormInputProps, 'label'>) => {
    const langLoveArr = [
        { value: 'Нет' },
        { value: 'Мужской' },
        { value: 'Женский' }
    ]


    var newValue = setValue;
    switch (setValue){
        case '0':
            newValue = 'Нет';
            break;
        case '1':
            newValue = 'Мужской';
            break;
        case '2':
            newValue = 'Женский';
            break;
    }

    return(
        <>
            <FormInputDropdown
                name={name} control={control}
                defaultValue={newValue}
                label="Пол"
                options={langLoveArr}
            />
        </>
    )
}

export default CfgGenderList;
