// QuizButton.tsx
import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface QuizButtonProps extends ButtonProps {
    onClick: () => void;
}

const GradientButton = styled(Button)(({ theme }) => ({
    //background: `linear-gradient(to right, #05B5CD, #02D2D2)`,
    backgroundColor: '#05B5CD',
    '-webkit-box-shadow': '-80px 0px 40px -40px rgba(2, 210, 210, 1) inset',
    '-moz-box-shadow': '-80px 0px 40px -40px rgba(2, 210, 210, 1) inset',
    boxShadow: '-80px 0px 40px -40px rgba(2, 210, 210, 1) inset',
    color: '#fff',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        //background: `linear-gradient(to right, #04A8C1, #02BDDB)`, // Darkened colors
        backgroundColor: '#059EB2',
        '-webkit-box-shadow': '-80px 0px 40px -40px rgba(2, 183, 183, 1) inset',
        '-moz-box-shadow': '-80px 0px 40px -40px rgba(2, 183, 183, 1) inset',
        boxShadow: '-80px 0px 40px -40px rgba(2, 183, 183, 1) inset',
    },
    '&[disabled]': {
        backgroundColor: '#02BDDB',
        '-webkit-box-shadow': '-80px 0px 40px -40px rgba(2, 189, 219, 1) inset',
        '-moz-box-shadow': '-80px 0px 40px -40px rgba(2, 189, 219, 1) inset',
        boxShadow: '-80px 0px 40px -40px rgba(2, 189, 219, 1) inset',
        cursor: 'not-allowed',
        color: '#d0d0d0',
        filter: 'grayscale(70%) opacity(1)',
    },
}));

const QuizButton: React.FC<QuizButtonProps> = ({ onClick, disabled, children, ...rest }) => (
    <GradientButton className="gradient-button" variant="contained" onClick={onClick} disabled={disabled} {...rest}>
        {children}
    </GradientButton>
);

export default QuizButton;
