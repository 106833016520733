import { combineReducers } from "@reduxjs/toolkit";
import { goroskopAPI } from "../services/goroskop";
import adminSlice from "./adminSlice";
import cabinetSlice from "./cabinetSlice";
import mainSlice from "./mainSlice";
import quizSlice from "./quizSlice";
import tableSlice from './tableSlice'
import configSlice from './configSlice'


export const rootReducer = combineReducers({
    tableSlice: tableSlice,
    adminSlice: adminSlice,
    cabinet: cabinetSlice,
    config: configSlice,
    quiz: quizSlice,
    main: mainSlice,
    [goroskopAPI.reducerPath]: goroskopAPI.reducer, 
    
})