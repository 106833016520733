import { FormInputProps } from "../../types/formInputProps"
import FormInputDropMultiSelect from "./form-component/FormInputDropMultiSelect"

const RegionsDropDown = ({name, control, setValue}: Omit<FormInputProps, 'label'>) => {

    // localStorage.setItem( 'memoriesdata', JSON.stringify( arr ) );
    // var arr = JSON.parse( localStorage.getItem('memoriesdata') );

    {/*
    // @ts-ignore
    */}
    let str = localStorage.getItem('regions');
    if (str === null) {
        str = '[]';
    }
    const options = JSON.parse(str);

    return (
        <>
            <FormInputDropMultiSelect
                name={name}
                control={control}
                setValue={setValue}
                label="Выберите регион"
                options={options}
            />
        </>
    )
}



export default RegionsDropDown

