import React from "react";
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../../social.css';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {getCookie} from "../../functions/Cookie";
//import Typography from '@mui/material/Typography';
// https://mui.com/material-ui/react-tooltip/

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));
const Socialicons = (opacityOff?: boolean) => {
    const tariff = getCookie('tariff');
    let listIcons = {
        media: [
            {name: 'ВКонтакте',     size: '40px', icon: 'vk',   link: (
                    (tariff != null && tariff !== '0') ? 'https://vk.me/svastro_anonce' : 'https://vk.me/svastro'
                )
            },
            {name: 'WhatsApp',      size: '38px', icon: 'wa',   link: 'https://api.whatsapp.com/send?phone=79506142432'},
            {name: 'Telegram',      size: '38px', icon: 'tg',   link: 'https://t.me/svat_astrolog_com'},
            {name: 'Viber',         size: '34px', icon: 'vb',   link: 'viber://contact?number=%2B79506142432'},
            {name: 'Одноклассники', size: '40px', icon: 'ok',   link: 'https://ok.ru/goroskoper'},
        ],
        chats: [
            {name: 'Instagram',     size: '38px', icon: 'inst', link: 'https://www.instagram.com/svat_astrolog'},
            {name: 'TikTok',        size: '40px', icon: 'tt',   link: 'https://www.tiktok.com/@svat_astrolog'},
            {name: 'YouTube',       size: '38px', icon: 'yt',   link: 'https://www.youtube.com/@Goroskoper2000'},
            {name: 'Facebook',      size: '40px', icon: 'fb',   link: 'https://www.facebook.com/goroskoper2000.ru'},
        ],
    };
    // (tariff == '0' ? 'https://vk.me/svastro' : 'https://vk.me/svastro_anonce')
    /*console.log('listIcons',{
        tariff: tariff,
        link: listIcons.media[0].link,
    });*/
    if(tariff != null && tariff !== '0') {
        listIcons.media[0].link = 'https://vk.me/svastro_anonce';
    }

    return (
        <>
            <div className={'social-kndev r1'}>
                {listIcons.media.map((el, index) => {
                    return (
                        <div key={index}>
                            <BootstrapTooltip title={el.name}>
                                <a href={el.link} target={'_blank'} rel={'nofollow noreferrer'}>
                                    <span
                                        className={opacityOff ? 'icon-kndev' : 'icon-kndev icon-opacity-kndev'}
                                        style={{
                                            backgroundImage: ('url("/images/social/'+el.icon+'.svg")'),
                                            backgroundSize: el.size,
                                        }}/>
                                </a>
                            </BootstrapTooltip>
                        </div>
                    );
                })}
            </div>
            <div className={'social-kndev r2'}>
                {listIcons.chats.map((el, index) => {
                    return (
                        <div key={index}>
                            <BootstrapTooltip title={el.name}>
                                <a href={el.link} target={'_blank'} rel={'nofollow noreferrer'}>
                                    <span
                                        className={opacityOff ? 'icon-kndev' : 'icon-kndev icon-opacity-kndev'}
                                        style={{
                                            backgroundImage: ('url("/images/social/'+el.icon+'.svg")'),
                                            backgroundSize: el.size,
                                    }}/>
                                </a>
                            </BootstrapTooltip>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default Socialicons