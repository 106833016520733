import {FormControlLabel, FormHelperText, Radio, RadioGroup} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {
    options: string[],
    forceValidate?: boolean
}

const FormInputRadioButton: React.FC<CheBoxProps> = ({
    name,
    control,
    options,
    defaultValue,
    forceValidate
}) => {
    const validate = (value: string | undefined) => {
        if (forceValidate && !value) {
            return 'Выберите хотя бы один вариант'; // Сообщение об ошибке
        }
        return undefined;
    };
    return (
        <div style={{border: '2px solid', borderColor: '#8768c8', borderRadius: '10px'}}>
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{ validate }}
            render={
                ({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    <RadioGroup
                        row
                        aria-labelledby={`input-radio-${name}`}
                        name={"position" + name}
                        id={"input-radio-" + name}
                        defaultValue={defaultValue}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        onChange={e => {
                            //console.log('onChange',e.target.value)
                            onChange(e.target.value)
                        }}
                    >
                        {options.map((option: string) => {
                            return (
                                <FormControlLabel
                                    key={option + name}
                                    value={option}
                                    sx={{
                                        color: 'white',
                                        borderRadius: '5px',
                                        backgroundColor: '#8768c8',
                                        margin: '5px',
                                        width: 'calc(50% - 10px)',
                                        boxSizing: 'border-box',
                                        maxWidth: '50%',
                                    }}
                                    label={option}
                                    labelPlacement="start"
                                    control={<Radio
                                        sx={{
                                            '&, &.Mui-checked': {
                                                color: '#ffffff',
                                            },
                                        }}
                                        checked={value === option}
                                    />}

                                />
                            );
                        })}
                    </RadioGroup>
                    {error && (
                        <FormHelperText error style={{textAlign: 'center'}}>{error.message}</FormHelperText>
                    )}
                </>
            )}
        />
        </div>
    );
};

export default FormInputRadioButton

