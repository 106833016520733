import NotFound from '../../routes/NotFound';
import * as React from 'react';
import { FC, useEffect, useState } from "react";
import { blueGrey, deepPurple, purple, deepOrange } from '@mui/material/colors';
//import { TextField } from "@mui/material";
import {useGetApiQuery, useSubmitDataWithRerenderMutation} from "../../services/goroskop";
import {Data} from "../../types/data";
import Link from '@mui/material/Link';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
//import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
//import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
//import PetsIcon from '@mui/icons-material/Pets';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { translateEast, translateWest } from "../../translate/symbols";
import RuleIcon from '@mui/icons-material/Rule';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const Config: FC = () => {
    var storageData: any[];
    let { type } = useParams()

    const { data, error, isLoading } = useGetApiQuery('config.php?'+(type ? type+'=ok' : ''))

    const getInput = (defaultValue: string | number, strFrom: string, strTo: string ) => {
        return (
            <>
                {defaultValue}
                {/*<TextField
                    sx={{ width: '60px'}}
                    id={type+'-'+strFrom+'-'+strTo}
                    type="number"
                    hiddenLabel
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="filled"
                    size="small"
                    prefix="%"
                    defaultValue={defaultValue}
                    onChange={e => {
                        console.log({
                            value: e.target.value,
                            type: type,
                            from: strFrom,
                            to: strTo
                        })
                    }}
                />*/}
                {/*<Link to={{ pathname: "/admin/config/"+type+"/"+strFrom+'-'+strTo}} component={RouterLink}>
                    <TextSnippetIcon color="primary" />
                </Link>*/}
            </>
        )
    }

    //const updateValue(type)

    const printArray = (arr: Data[]) => {
        storageData = arr;
        if(type == 'east') {
            return arr.map((row) => (
                <StyledTableRow
                    key={type}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <StyledTableCell sx={{whiteSpace: "nowrap"}} component="th" scope="row">
                        <Link to={{ pathname: "/admin/config/"+type+"/single-"+row.animal}} component={RouterLink}>
                            <TextSnippetIcon sx={{margin: "0 0 -7px -10px"}} color="primary" />
                        </Link>
                        {translateEast(row.animal)}
                    </StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.chicken, row.animal, 'chicken' )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.dog,     row.animal, 'dog'     )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.dragon,  row.animal, 'dragon'  )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.horse,   row.animal, 'horse'   )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.monkey,  row.animal, 'monkey'  )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.ox,      row.animal, 'ox'      )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.pig,     row.animal, 'pig'     )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.rabbit,  row.animal, 'rabbit'  )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.rat,     row.animal, 'rat'     )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.sheep,   row.animal, 'sheep'   )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.snake,   row.animal, 'snake'   )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.tiger,   row.animal, 'tiger'   )}</StyledTableCell>
                </StyledTableRow>
            ));
        }else if(type == 'west') {
            return arr.map((row) => (
                <StyledTableRow
                    key={type}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <StyledTableCell sx={{whiteSpace: "nowrap"}} component="th" scope="row">
                        <Link to={{ pathname: "/admin/config/"+type+"/single-"+row.zodiak}} component={RouterLink}>
                            <TextSnippetIcon sx={{margin: "0 0 -7px -10px"}} color="primary" />
                        </Link>
                        {translateWest(row.zodiak)}
                    </StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.aries,       row.zodiak, 'aries'       )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.taurus,      row.zodiak, 'taurus'      )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.gemini,      row.zodiak, 'gemini'      )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.cancer,      row.zodiak, 'cancer'      )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.leo,         row.zodiak, 'leo'         )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.virgo,       row.zodiak, 'virgo'       )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.libra,       row.zodiak, 'libra'       )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.scorpio,     row.zodiak, 'scorpio'     )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.sagittarius, row.zodiak, 'sagittarius' )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.capricorn,   row.zodiak, 'capricorn'   )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.aquarius,    row.zodiak, 'aquarius'    )}</StyledTableCell>
                    <StyledTableCell align="center">{getInput(row.pisces,      row.zodiak, 'pisces'      )}</StyledTableCell>
                </StyledTableRow>
            ));
        }
    }
    const printConfigTable = (data: Data[] | undefined, east: boolean) => {
        // console.log('printConfigTable',data);
        if(data) {
            return (
                <TableContainer component={Paper}>
                    <h1>{ type == 'east' ? 'Восточная астрология' : 'Западная астрология'}</h1>
                    <Table sx={{ minWidth: 650, width: '100%' }} aria-label="simple table">
                        <TableHead>
                        { type == 'east' ?
                            <TableRow>
                                <StyledTableCell>Зодиак</StyledTableCell>
                                <StyledTableCell align="right">Петух</StyledTableCell>
                                <StyledTableCell align="right">Собака</StyledTableCell>
                                <StyledTableCell align="right">Дракон</StyledTableCell>
                                <StyledTableCell align="right">Лошадь</StyledTableCell>
                                <StyledTableCell align="right">Обезьяна</StyledTableCell>
                                <StyledTableCell align="right">Бык</StyledTableCell>
                                <StyledTableCell align="right">Кабан</StyledTableCell>
                                <StyledTableCell align="right">Кролик</StyledTableCell>
                                <StyledTableCell align="right">Крыса</StyledTableCell>
                                <StyledTableCell align="right">Коза</StyledTableCell>
                                <StyledTableCell align="right">Змея</StyledTableCell>
                                <StyledTableCell align="right">Тигр</StyledTableCell>
                            </TableRow>
                            :
                            <TableRow>
                                <StyledTableCell>Знак</StyledTableCell>
                                <StyledTableCell align="right">Овен</StyledTableCell>
                                <StyledTableCell align="right">Телец</StyledTableCell>
                                <StyledTableCell align="right">Близнецы</StyledTableCell>
                                <StyledTableCell align="right">Рак</StyledTableCell>
                                <StyledTableCell align="right">Лев</StyledTableCell>
                                <StyledTableCell align="right">Дева</StyledTableCell>
                                <StyledTableCell align="right">Весы</StyledTableCell>
                                <StyledTableCell align="right">Скорпион</StyledTableCell>
                                <StyledTableCell align="right">Стрелец</StyledTableCell>
                                <StyledTableCell align="right">Козерог</StyledTableCell>
                                <StyledTableCell align="right">Водолей</StyledTableCell>
                                <StyledTableCell align="right">Рыбы</StyledTableCell>
                            </TableRow>
                        }
                        </TableHead>
                        <TableBody>
                            {printArray(data)}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        return null;
    }

    const getIcon = (icon: string) => {
        switch (icon){
            case 'start':
                return (<Avatar sx={{ bgcolor: blueGrey[500] }}><TextsmsOutlinedIcon /></Avatar>)
                break;
            case 'east':
                return (<Avatar sx={{ bgcolor: purple[500] }}><DisplaySettingsIcon /></Avatar>)
                break;
            case 'west':
                return (<Avatar sx={{ bgcolor: deepPurple[500] }}><DisplaySettingsIcon /></Avatar>)
                break;
            case 'end':
                return (<Avatar sx={{ bgcolor: blueGrey[500] }}><TextsmsOutlinedIcon /></Avatar>)
                break;
            case 'tariff':
                return (<Avatar sx={{ bgcolor: deepOrange[500] }}><RuleIcon /></Avatar>)
                break;
            default:
                return (<Avatar sx={{ bgcolor: blueGrey[500] }}><DisplaySettingsIcon /></Avatar>)
                break;
        }
    }
    const printItemList = (link:string, text1:string,text2:string, icon:string) => {
        // <Link to={{ pathname: "/admin/config/static/predislovie"}} component={RouterLink} variant="body2" ><DisplaySettingsIcon /></Link> Предисловие</h1>
        return(<ListItem>
                   <ListItemAvatar>
                       <Link to={{ pathname: link}} component={RouterLink}>
                           {getIcon(icon)}
                       </Link>
                   </ListItemAvatar>
                   <ListItemText primary={text1} secondary={text2} />
               </ListItem>);
    }
    // console.log('type',type);
    switch (type){
        case 'east':
        case 'west':
            return (
                <>
                    {isLoading && <h1>Загрузка...</h1>}
                    {data && printConfigTable(data, type == 'east')}
                </>
            )
            break;
        case undefined:
            return (
                <>
                    {printItemList(
                        "/admin/config/tariff",
                        "Тарифы",
                        "Настройка тарифов",
                        "tariff")}
                    <hr />
                    {printItemList(
                    "/admin/config/static/predislovie",
                    "Предисловие",
                    "Текст в начале",
                    "start")}
                    {printItemList(
                    "/admin/config/west",
                    "Западная астрология",
                    "Настройка западной астрологии",
                    "east")}
                    {printItemList(
                     "/admin/config/east",
                    "Восточная астрология",
                    "Настройка восточной астрологии",
                    "west")}
                    {printItemList(
                     "/admin/config/static/posleslovie",
                    "Послесловие",
                    "Текст в конце",
                    "end")}
                </>
            )
            break;
        default:
            return(<NotFound />);
            break;
    }
}

export default Config