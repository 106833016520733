import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Data } from "../types/data";

export type configObj = Partial<Pick<Data, "status"
    | "text" | "msg"
>>

interface ConfigState {
    printed: configObj
}

const initialState = {} as ConfigState

const configSlice = createSlice({
    name: 'cabinet',
    initialState,
    reducers: {
        changePrintedObject(state, action: PayloadAction<ConfigState>) {
            state.printed = action.payload.printed
        },
    }
})

export const { changePrintedObject } = configSlice.actions

export default configSlice.reducer
