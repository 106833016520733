const regionCodes = [
    {value: '77',    label: 'Москва'},
    {value: '177',   label: 'Московская область'},
    {value: '78',    label: 'Санкт-Петербург'},
    {value: '19',    label: 'Абакан'},
    {value: '122',   label: 'Алтайский край'},
    {value: '128',   label: 'Амурская область'},
    {value: '29',    label: 'Архангельск'},
    {value: '129',   label: 'Архангельская область'},
    {value: '30',    label: 'Астрахань'},
    {value: '130',   label: 'Астраханская область'},
    {value: '94',    label: 'Байконур'},
    {value: '22',    label: 'Барнаул'},
    {value: '31',    label: 'Белгород'},
    {value: '131',   label: 'Белгородская область'},
    {value: '79',    label: 'Биробиджан'},
    {value: '28',    label: 'Благовещенск'},
    {value: '32',    label: 'Брянск'},
    {value: '132',   label: 'Брянская область'},
    {value: '53',    label: 'Великий Новгород'},
    {value: '25',    label: 'Владивосток'},
    {value: '15',    label: 'Владикавказ'},
    {value: '33',    label: 'Владимир'},
    {value: '133',   label: 'Владимирская область'},
    {value: '34',    label: 'Волгоград'},
    {value: '134',   label: 'Волгоградская область'},
    {value: '35',    label: 'Вологода'},
    {value: '135',   label: 'Вологодская область'},
    {value: '36',    label: 'Воронеж'},
    {value: '136',   label: 'Воронежская область'},
    {value: '4',     label: 'Горно-Алтайск'},
    {value: '80',    label: 'Донецк'},
    {value: '180',   label: 'Донецкая Народная Республика'},
    {value: '179',   label: 'Еврейская автономная область'},
    {value: '66',    label: 'Екатеринбург'},
    {value: '175',   label: 'Забайкальский край'},
    {value: '185',   label: 'Запорожская область'},
    {value: '85',    label: 'Запорожье'},
    {value: '37',    label: 'Иваново'},
    {value: '137',   label: 'Ивановская область'},
    {value: '18',    label: 'Ижевск'},
    {value: '118',   label: 'Республика Удмуртская'},
    {value: '38',    label: 'Иркутск'},
    {value: '138',   label: 'Иркутская область'},
    {value: '12',    label: 'Йошкар-Ола'},
    {value: '16',    label: 'Казань'},
    {value: '39',    label: 'Калининград'},
    {value: '139',   label: 'Калининградская область'},
    {value: '40',    label: 'Калуга'},
    {value: '140',   label: 'Калужская область'},
    {value: '141',   label: 'Камчатский край'},
    {value: '42',    label: 'Кемерово'},
    {value: '142',   label: 'Кемеровская область'},
    {value: '43',    label: 'Киров'},
    {value: '143',   label: 'Кировская область'},
    {value: '44',    label: 'Кострома'},
    {value: '144',   label: 'Костромская область'},
    {value: '23',    label: 'Краснодар'},
    {value: '123',   label: 'Краснодарский край'},
    {value: '24',    label: 'Красноярск'},
    {value: '124',   label: 'Красноярский край'},
    {value: '182',   label: 'Крым'},
    {value: '45',    label: 'Курган'},
    {value: '145',   label: 'Курганская область'},
    {value: '46',    label: 'Курск'},
    {value: '146',   label: 'Курская область'},
    {value: '17',    label: 'Кызыл'},
    {value: '178',   label: 'Ленинградская область'},
    {value: '48',    label: 'Липецк'},
    {value: '148',   label: 'Липецкая область'},
    {value: '81',    label: 'Луганск'},
    {value: '181',   label: 'Луганская Народная Республика'},
    {value: '49',    label: 'Магадан'},
    {value: '149',   label: 'Магаданская область'},
    {value: '6',     label: 'Магас'},
    {value: '1',     label: 'Майкоп'},
    {value: '5',     label: 'Махачкала'},
    {value: '77',    label: 'Москва'},
    {value: '177',   label: 'Московская область'},
    {value: '51',    label: 'Мурманск'},
    {value: '151',   label: 'Мурманская область'},
    {value: '7',     label: 'Нальчик'},
    {value: '83',    label: 'Нарьян-Мар'},
    {value: '183',   label: 'Ненецкий автономный округ'},
    {value: '152',   label: 'Нижегородская область'},
    {value: '52',    label: 'Нижний Новгород'},
    {value: '153',   label: 'Новгородская область'},
    {value: '54',    label: 'Новосибирск'},
    {value: '154',   label: 'Новосибирская область'},
    {value: '55',    label: 'Омск'},
    {value: '155',   label: 'Омская область'},
    {value: '57',    label: 'Орёл'},
    {value: '56',    label: 'Оренбург'},
    {value: '156',   label: 'Оренбургская область'},
    {value: '157',   label: 'Орловская область'},
    {value: '58',    label: 'Пенза'},
    {value: '158',   label: 'Пензенская область'},
    {value: '159',   label: 'Пермский край'},
    {value: '59',    label: 'Пермь'},
    {value: '10',    label: 'Петрозаводск'},
    {value: '41',    label: 'Петропавловск-Камчатский'},
    {value: '125',   label: 'Приморский край'},
    {value: '60',    label: 'Псков'},
    {value: '160',   label: 'Псковская область'},
    {value: '101',   label: 'Республика Адыгея (Адыгея)'},
    {value: '104',   label: 'Республика Алтай'},
    {value: '102',   label: 'Республика Башкортостан'},
    {value: '103',   label: 'Республика Бурятия'},
    {value: '105',   label: 'Республика Дагестан'},
    {value: '106',   label: 'Республика Ингушетия'},
    {value: '107',   label: 'Республика Кабардино-Балкарская'},
    {value: '108',   label: 'Республика Калмыкия'},
    {value: '109',   label: 'Республика Карачаево-Черкесская'},
    {value: '110',   label: 'Республика Карелия'},
    {value: '111',   label: 'Республика Коми'},
    {value: '182',   label: 'Республика Крым'},
    {value: '112',   label: 'Республика Марий Эл'},
    {value: '113',   label: 'Республика Мордовия'},
    {value: '114',   label: 'Республика Саха (Якутия)'},
    {value: '115',   label: 'Республика Северная Осетия — Алания'},
    {value: '116',   label: 'Республика Татарстан (Татарстан)'},
    {value: '117',   label: 'Республика Тыва'},
    {value: '118',   label: 'Республика Удмуртская'},
    {value: '119',   label: 'Республика Хакасия'},
    {value: '61',    label: 'Ростов-на-дону'},
    {value: '161',   label: 'Ростовская область'},
    {value: '162',   label: 'Рязанская область'},
    {value: '62',    label: 'Рязань'},
    {value: '89',    label: 'Салехард'},
    {value: '63',    label: 'Самара'},
    {value: '163',   label: 'Самарская область'},
    {value: '78',    label: 'Санкт-Петербург'},
    {value: '178',   label: 'Ленинградская область'},
    {value: '13',    label: 'Саранск'},
    {value: '64',    label: 'Саратов'},
    {value: '164',   label: 'Саратовская область'},
    {value: '165',   label: 'Сахалинская область'},
    {value: '166',   label: 'Свердловская область'},
    {value: '92',    label: 'Севастополь'},
    {value: '82',    label: 'Симферополь'},
    {value: '67',    label: 'Смоленск'},
    {value: '167',   label: 'Смоленская область'},
    {value: '26',    label: 'Ставрополь'},
    {value: '126',   label: 'Ставропольский край'},
    {value: '11',    label: 'Сыктывкар'},
    {value: '68',    label: 'Тамбов'},
    {value: '168',   label: 'Тамбовская область'},
    {value: '169',   label: 'Тверская область'},
    {value: '69',    label: 'Тверь'},
    {value: '70',    label: 'Томск'},
    {value: '170',   label: 'Томская область'},
    {value: '71',    label: 'Тула'},
    {value: '171',   label: 'Тульская область'},
    {value: '172',   label: 'Тюменская область'},
    {value: '72',    label: 'Тюмень'},
    {value: '3',     label: 'Улан-Удэ'},
    {value: '73',    label: 'Ульяновск'},
    {value: '173',   label: 'Ульяновская область'},
    {value: '2',     label: 'Уфа'},
    {value: '27',    label: 'Хабаровск'},
    {value: '127',   label: 'Хабаровский край'},
    {value: '86',    label: 'Ханты-Мансийск'},
    {value: '186',   label: 'Ханты-Мансийский автономный округ — Югра'},
    {value: '84',    label: 'Херсон'},
    {value: '184',   label: 'Херсонская область'},
    {value: '21',    label: 'Чебоксары'},
    {value: '74',    label: 'Челябинск'},
    {value: '174',   label: 'Челябинская область'},
    {value: '9',     label: 'Черкесск'},
    {value: '195',   label: 'Чеченская республика'},
    {value: '95',    label: 'Чечня'},
    {value: '75',    label: 'Чита'},
    {value: '121',   label: 'Чувашская Республика (Чувашия)'},
    {value: '87',    label: 'Чукотский автономный округ'},
    {value: '187',   label: 'Чукотский автономный округ'},
    {value: '8',     label: 'Элиста'},
    {value: '65',    label: 'Южно-Сахалинск'},
    {value: '14',    label: 'Якутск'},
    {value: '189',   label: 'Ямало-Ненецкий автономный округ'},
    {value: '76',    label: 'Ярославль'},
    {value: '176',   label: 'Ярославская область'},
    {value: '0',     label: 'Моего региона нет в списке'},
    {value: '-1',    label: '- Республика Баларусь -'},
    {value: 'BY-7',  label: 'Минск'},
    {value: 'BY-1',  label: 'Брестская область'},
    {value: 'BY-2',  label: 'Витебская область'},
    {value: 'BY-3',  label: 'Гомельская область'},
    {value: 'BY-4',  label: 'Гродненская область'},
    {value: 'BY-5',  label: 'Минская область'},
    {value: 'BY-6',  label: 'Могилёвская область'},
    {value: '0',     label: 'Моего региона нет в списке'},
];

export default regionCodes