import {Checkbox, FormControlLabel, FormHelperText, Radio, RadioGroup} from "@mui/material";
import { pink } from "@mui/material/colors";
import React, { useEffect, useReducer } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";
import T from "../OnlyText";
import { GetLangloveDisabled } from "../../../functions/getLangloveDisabled";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {
    forceValidate?: boolean
}

const FormInputLangLoves2: React.FC<CheBoxProps> = ({
        name,
        control,
        forceupdate,
        forceValidate
    }) => {
    const allOptions = [
        /* "Одобрение"*/
        "Прикосновения",
        "Время",
        "Помощь",
        "Подарки",
    ];

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const langLove = localStorage.getItem('prelanglove1')
    var lastLangLove = langLove;

    if(forceupdate) {
        var forceInterval = setInterval(function () {
            let newLangLove = localStorage.getItem('prelanglove1');
            if(lastLangLove !== newLangLove) {
                clearInterval(forceInterval);
                forceUpdate();
            }
        }, 1000);
    }

    const changeHandler = (value: string) => {
        console.log('changeHandler', value)
    }

    // Validation function
    const validate = (value?: string) => {
        if (forceValidate && !value) {
            return 'Выберите хотя бы один вариант';
        }
        return undefined;
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={{ validate }}
            render={
            ({ field: { onChange, value }, fieldState: { error } }) => (
            <>
                <div style={{border: '2px solid', borderColor: '#8768c8', borderRadius: '10px'}}>
                <RadioGroup
                    row
                    aria-labelledby={`input-radio-${name}`}
                    name={"position" + name}
                    id={"input-radio-" + name}
                    defaultValue="top"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    {allOptions.map(option => {
                        return (
                            <FormControlLabel
                                key={option + name}
                                value={option ?? ' '}
                                sx={{
                                    color: 'white',
                                    borderRadius: '5px',
                                    backgroundColor: '#8768c8',
                                    margin: '5px',
                                    width: 'calc(50% - 10px)',
                                    boxSizing: 'border-box',
                                    maxWidth: '50%',
                                }}
                                label={option}
                                labelPlacement="start"
                                onChange={(e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
                                    const target = e.currentTarget as HTMLInputElement; // Кастим currentTarget
                                    onChange(checked ? target.value : ''); // Обработка значения
                                    changeHandler(checked ? target.value : ''); // Передача значения в handler
                                }}
                                control={<Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                            color: '#ffffff',
                                        },
                                    }}
                                    checked={!GetLangloveDisabled(option) && value === option}
                                />}
                                disabled={GetLangloveDisabled(option)}
                            />
                        );
                    })}
                </RadioGroup>
                </div>
                {error && (
                    <FormHelperText error style={{textAlign: 'center', marginTop: '5px'}}>{error.message}</FormHelperText>
                )}
            </>
            )}
        />
    );
};

export default FormInputLangLoves2;
