import React from "react";
import {Typography} from "@mui/material";
import TextureIcon from "@mui/icons-material/Texture";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const printUserInfo = (fieldName: string, fieldData: string | number | null, link: boolean, except?: string) => {
    if(fieldData == 'hidden'){
        return (<Typography
            variant="subtitle1"
            component="p"
            sx={{ marginTop: 3, marginBottom: 3 }}
            className='hiddenField'>
                <b>{fieldName}:</b> <TextureIcon />
        </Typography>);
    }else{
        if(except && fieldData == except){
            return null;
        }
    }

    if(!link) {
        return (<Typography
            variant="subtitle1"
            component="p"
            sx={{ marginTop: 3, marginBottom: 3 }}>
                <b>{fieldName}:</b> {fieldData}
        </Typography>);
    }else{
        if(fieldData) {
            let mylink = fieldData.toString();
            return (<Typography
                variant="subtitle1"
                component="p"
                sx={{ marginTop: 3, marginButtom: 3 }}>
                    <b>{fieldName}:</b> <a href={mylink} target='_blank' rel={'nofollow noreferrer'}>Перейти&nbsp;<OpenInNewIcon sx={{ width: '15px', height:'15px'}} /></a></
                Typography>);
        }
    }
    return null;
}
