import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme();

const NotFound: FC = () => {

    let location = useLocation();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{
                textAlign: 'center',
            }}>
                <h1>Страница не найдена</h1>
                <img style={{
                    margin: '0 auto 0 auto',
                    width: '64px',
                    height: '64px'  }} src='/images/logo.png' />
                <h3>
                    <span>Проверьте правильность введеного адреса:</span>
                    <br />
                    <code style={{color: '#8a8a8a', margin: '10px 10px 10px 10px', display: 'block'}}>{location.pathname}</code>
                </h3>
                <hr />
                {/*<h1>Если вы заходите в систему первый раз пожалуйста обновите страницу</h1>
                <hr />*/}
                <Link to={{ pathname: "/"}}>Главная страница</Link>
            </div>
            <CssBaseline />
        </ThemeProvider>
    )
}

export default NotFound