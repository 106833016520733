import { Box, Button, Select } from "@mui/material";
import React, {FC, useCallback, useEffect} from "react";
import T from "../quiz/OnlyText";
import 'react-alice-carousel/lib/alice-carousel.css';
import { SubmitHandler, useForm } from "react-hook-form";
import ZodiakDropDown from "../quiz/zodiakDropDown";
import RegionsDropDown from "../quiz/regionDropDown";
import FormInputRadioButton from "../quiz/form-component/FormInputRadioButtons";
import { purple } from "@mui/material/colors";
import { useAppDispatch } from "../../hooks/hooks";
import { changeFilterObject, filtrationObj } from "../../reducers/cabinetSlice";
import FormInputText from "../quiz/form-component/FormInputText";
import {useGetApiQuery} from "../../services/goroskop";
import EnhancedTable from "../admin/constituents/Table";
import FormInputDropdown from "../quiz/form-component/FormInputDropDown"
import FormInputDropMultiSelect from "../quiz/form-component/FormInputDropMultiSelect"
import {getCookie} from "../../functions/Cookie";
import FormInputTextAria from "../quiz/form-component/FormInputTextArea"
import {alpha} from "@mui/material/styles";
//import FormInputMultiCheckbox from "../quiz/form-component/FormInputMultiCheckbox";
import FormInputGroupButton from "../quiz/form-component/FormInputGroupButton";
import FormInputDate from "../quiz/form-component/FormInputDate";
import { Data } from '../../types/data'
//import FormInputTextAreaCallback from "../quiz/form-component/FormInputTextAreaCallback"
//import regionCodes from "../forAll/regions";

const Filtration: FC = () => {

    const dispatch = useAppDispatch()

    const { data, error, isLoading, currentData } = useGetApiQuery('regions.php?all=y');

    function saveRegions(regs: any){
        if(regs !== '[]' && regs !== null && regs.length > 0){
            localStorage.setItem('regions', JSON.stringify(regs));
            return true;
        }
        return false;
    }

    const role = getCookie('role');

    const MemoizedRegions = useCallback(() =>
            <>
                {
                    data && saveRegions(data) && (
                        <RegionsDropDown
                            name="city"
                            control={control}
                            setValue={setValue}
                        />
                    )
                }
            </>
        , [currentData, name]);

    const { register, handleSubmit, formState: { errors }, control, setValue, watch } = useForm<filtrationObj>();

    const reset = () => dispatch(changeFilterObject({} as filtrationObj))

    let updatePrefilter: ReturnType<typeof setInterval>;

    // (localStorage.getItem('lastfilter') ?? '') : '')

    const onSubmit: SubmitHandler<filtrationObj> = (dataFilter) => {
        // @ts-ignore
        dispatch(changeFilterObject(dataFilter))

        clearInterval(updatePrefilter);
        updatePrefilter = setInterval(function(){
            if(dataFilter) {
                const target = {};
                let newData = Object.assign(target, dataFilter);
                if(typeof(newData.prefilter) !== 'undefined') {
                    delete newData.prefilter
                }
                let resultFilter = JSON.stringify(newData);
                setValue('prefilter', resultFilter)
                localStorage.setItem('lastfilter', resultFilter);
                clearInterval(updatePrefilter);
            }
        }, 1000)
        /*setTimeout(function () {
            // @ts-ignore
            //document.getElementById('textarea-prefilter').value = JSON.stringify(dataFilter);
            // @ts-ignore
            prefilterDiv.value = JSON.stringify(dataFilter);
        }, 2000)*/
    };

    //console.log('getted resultRegion', useGetApiQuery('regions.php'));


    /*const reloadFilter = () => {
        let lastFilterStr = localStorage.getItem('lastfilter');
        console.log('lastFilterStr', lastFilterStr);
        if(typeof(lastFilterStr) === 'string' && lastFilterStr.length > 0){
            let lastFilter = JSON.parse(lastFilterStr);
            console.log('lastFilter', lastFilter);
            if(typeof(lastFilter) === 'object' && lastFilter !== null) {
                let arrayKeys = ['birthyear', 'city', 'langlove', 'langlove2', 'zodiak'];
                let newFilter: Data = lastFilter;
                for (const [key, value] of Object.entries(lastFilter)) {
                    if(!arrayKeys.includes(key)) {
                        // @ts-ignore
                        setValue(key, value);
                    }else{
                        /*
                        // @ts-ignore
                        setValue(key, value);
                        // @ts-ignore
                        for (var k = 0; k < value.length; k++) {
                            // @ts-ignore
                            setValue(key, value[k]);
                            // @ts-ignore
                            console.log('arr set ['+key+']',value[k]);
                        }* /
                    }
                }
            }
        }
    }*/

    // useEffect(() => reloadFilter(), [])

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <T>Регион поиска: </T>
            <Box>
                {error && <h1>Ошибка: {error}</h1>}
                {isLoading && <h3>Загрузка регионов</h3>}
                <MemoizedRegions />
            </Box>


            {/* <T>Выберите год рождения: </T>
            <div>
                <input type="range" id="volume" {...register('birthday')}
                    min={1950} max={new Date().getFullYear() - 18} />
                    <label htmlFor="volume">Volume</label>
            </div> */}


            <T>Выберите диапозон возрастов: </T>
            <FormInputText control={control} label='От какого года' name="birthdayFrom" sx={{width: 'calc(50% - 5px)', margin: '0 10px 0 0'}}/>
            <FormInputText control={control} label='До какого года' name="birthdayUntil" sx={{width: 'calc(50% - 5px)', margin: '0'}}/>

            <T>Выберите рост: </T>
            <FormInputText control={control} label='от' name="heightFrom" sx={{width: 'calc(50% - 5px)', margin: '0 10px 0 0'}}/>
            <FormInputText control={control} label='до' name="heightUntil" sx={{width: 'calc(50% - 5px)', margin: '0'}}/>
            <FormInputGroupButton control={control} name='height_empty' options={['Рост не указан']}/>

            <T>Курение?</T>
            <FormInputRadioButton
                control={control}
                name='smoke'
                options={['Да', 'Нет']}
            />
            <FormInputGroupButton control={control} name='smoke_empty' options={['Курение не указано']}/>

            <T>Наличие детей</T>
            <FormInputRadioButton
                control={control}
                name='children'
                options={['Да', 'Нет']}
            />
            <FormInputGroupButton control={control} name='children_empty' options={['Наличие детей не указано']}/>
            <T>Пол</T>
            <FormInputRadioButton
                control={control} name='gender'
                options={['М', 'Ж']}
            />


            <T>Знак по восточной астрологии: </T>
            <ZodiakDropDown
                name="birthyear"
                control={control}
                setValue={setValue}
                labelString={'Выберите'}
                options={[
                    { value: 'Крыса' },
                    { value: 'Бык' },
                    { value: 'Тигр' },
                    { value: 'Кролик' },
                    { value: 'Дракон' },
                    { value: 'Змея' },
                    { value: 'Лошадь' },
                    { value: 'Коза' },
                    { value: 'Обезьяна' },
                    { value: 'Петух' },
                    { value: 'Собака' },
                    { value: 'Кабан' },
                    { value: 'Не знаю' },
                ]} />

            <T>Знак по западной астрологии</T>
            <ZodiakDropDown
                name="zodiak"
                control={control}
                setValue={setValue}
                labelString={'Выберите'}
                options={[
                    { value: 'Овен' },
                    { value: 'Телец' },
                    { value: 'Близнецы' },
                    { value: 'Рак' },
                    { value: 'Лев' },
                    { value: 'Дева' },
                    { value: 'Весы' },
                    { value: 'Скорпион' },
                    { value: 'Стрелец' },
                    { value: 'Козерог' },
                    { value: 'Водолей' },
                    { value: 'Рыбы' },
                    { value: 'Не знаю' },
                ]}/>

            <T>Основной язык любви</T>
            <ZodiakDropDown
                name="langlove"
                control={control}
                setValue={setValue}
                labelString={'Выберите основной язык любви'}
                options={[
                    {value: 'N/A'},
                    {value: 'Одобрение'},
                    {value: 'Прикосновения'},
                    {value: 'Время'},
                    {value: 'Помощь'},
                    {value: 'Подарки'}
                ]} />
            {/*<FormInputRadioButton
                control={control} name='langlove'
                options={['Одобрение', 'Прикосновения', 'Время', 'Помощь', 'Подарки',]}
            />*/}
            <T>Второстепенный язык любви</T>
            <ZodiakDropDown
                name="langlove2"
                control={control}
                setValue={setValue}
                labelString={'Выберите второстепенный язык любви'}
                options={[
                    {value: 'N/A'},
                    {value: 'Одобрение'},
                    {value: 'Прикосновения'},
                    {value: 'Время'},
                    {value: 'Помощь'},
                    {value: 'Подарки'}
                ]}
            />
            {/*<FormInputRadioButton
                control={control} name='langlove2'
                options={['Одобрение', 'Прикосновения', 'Время', 'Помощь', 'Подарки',]}
            />*/}
            {/*<T>Вид поиска</T>
            <FormInputRadioButton
                control={control}
                name='targetsearch'
                options={['Активный', 'Пассивный', 'Консультация', 'Встреча']}
            />*/}
            <FormInputDate
                control={control}
                label='От даты регистрации'
                name="fromRegister"
                sx={{margin: '20px 5px 15px 5px', width: 'calc(50% - 10px)'}} />
            <FormInputDate
                control={control}
                label='До даты регистрации'
                name="untilRegister"
                sx={{margin: '20px 10px 15px 0', width: 'calc(50% - 10px)'}} />
            <FormInputTextAria
                control={control}
                setValue={setValue}
                label='Префильтр'
                name="prefilter"
                sx={{
                    height: '400px'
                }}
            />
            {/*
            ...((role !== 'ok_admin' && role !== 'ok_mainModer' && role !== 'consideration') && {
                display: 'none'
            })
            */}
            <Button type="submit" variant="contained"
                sx={{ marginLeft: '5%', bgcolor: purple[600] }} >Применить фильтры</Button>
            <Button type="submit" variant="contained" onClick={() => reset()}
                sx={{ marginLeft: '5%', bgcolor: purple[600] }} >Сбросить фильтры</Button>

        </form>
    )
}

export default Filtration
