export const getFamilyStatutes = (genderSet?: string) => {
    let gender = localStorage.getItem('gender');
    if(genderSet && (genderSet == 'М' || genderSet == 'Ж')){
        gender = genderSet;
    }
    if(gender){
        switch (gender){
            case 'М':
                return ['Холост', 'Женат'];
                break;
            case 'Ж':
                return ['Не замужем', 'Замужем'];
                break;
        }
    }
    return ['Холост', 'Не замужем', 'Женат', 'Замужем'];
}

export const getFamilyStatusDefault = () => {
    let gender = localStorage.getItem('gender');
    if(gender){
        switch (gender){
            case 'М':
                return 'Холост';
                break;
            case 'Ж':
                return 'Не замужем';
                break;
        }
    }
}

export const getLanglove2Access = (love1: string, love2: string) => {
    let access: any[] = [];
    switch (love1){
        case 'Одобрение':{  // Одобрение
            access = ["Прикосновения", "Время", "Помощь", "Подарки"]; // Прикосновения, Время, Помощь, Подарки
        }break;
        case 'Время':{  // Время
            access = ["Прикосновения", "Помощь", "Подарки"]; // Прикосновения, Помощь, Подарки
        }break;
        case 'Помощь':{  // Помощь
            access = ["Прикосновения", "Подарки"]; // Прикосновения, Подарки
        }break;
        case 'Подарки':{  // Подарки
            access = ["Прикосновения"]; // Прикосновения
        }break;
        case 'Прикосновения':
        default: {
            access = [];
        } break;
    }
    return access.includes(love2);
}
