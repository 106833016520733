import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
//import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormInputProps } from "../../../types/formInputProps";
import { useFormContext, Controller } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface CheBoxProps extends FormInputProps {
    options: Options[]
}

type Options = { value: string; label?: string }

const FormInputDropMultiSelect: React.FC<CheBoxProps> = ({
  name,
  control,
  label,
  options
}) => {
    const generateSingleOptions = () => {
        return options.map((option: Options) => {
            return (
                <MenuItem key={option.value} value={option.value ?? ''}>
                    <Checkbox checked={targetName.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label ? option.label : option.value} />
                </MenuItem>
            );
        });
    };

    const [targetName, setTargetName] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof targetName>) => {
        const {
            target: { value },
        } = event;
        setTargetName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl fullWidth size={"small"}>
            <InputLabel>{label}</InputLabel>
            <Controller
                render={({ field: { onChange, value } }) => {

                    return (
                        <Select
                            onChange={(e) => {
                                onChange(e)
                                handleChange(e)
                            }}
                            labelId={name}
                            id={name}
                            multiple
                            value={targetName}
                            input={<OutlinedInput label={label} />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {generateSingleOptions()}
                        </Select>
                    )

                }}
                control={control}
                name={name}
            />
        </FormControl>
    );

    /* return (
        <div>
            <FormControl fullWidth size={"small"}>
                <InputLabel>{label}</InputLabel>
                <Select
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    value={targetName}
                    onChange={handleChange}
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    control={control}
                    name={name}
                >
                    {generateSingleOptions()}
                </Select>
            </FormControl>
        </div>
    ); */
}

export default FormInputDropMultiSelect
