import { TextareaAutosize } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";


const FormInputTextAria = ({ name, control, label, style, defaultValue }: FormInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => (
                <TextareaAutosize
                    aria-label="empty textarea"
                    name={name}
                    id={`textarea-${name}`}
                    placeholder={label}
                    onChange={onChange}
                    minRows={3}
                    value={value ?? ''}
                    style={{
                        fontSize: '15px',
                        width: '100%',
                        margin: '20px 0 0 0',
                        borderRadius: '5px',
                        maxWidth: 'calc(100% - 36px)',
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        padding: '1em',
                        ...style
                    }}

                />
            )}
        />
    );
};
export default FormInputTextAria
