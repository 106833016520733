import { Button, Card, CardActionArea, CardContent, CardMedia, styled, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { FC, useState, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import ReactInputMask from "react-input-mask"
import {  useSubmitDataWithRerenderMutation } from "../../services/goroskop"
import restrictRole from "../../functions/restrictRole"
import FormInputDate from "../quiz/form-component/FormInputDate"
import FormInputDropdown from "../quiz/form-component/FormInputDropDown"
import FormInputRadioButton from "../quiz/form-component/FormInputRadioButtons"
import FormInputText from "../quiz/form-component/FormInputText"
import FormInputTextAria from "../quiz/form-component/FormInputTextArea"
import BirthyearList from "../quiz/birthyearList"
import T from "../quiz/OnlyText"
import PopUpImage from "../quiz/PopUpImage"
import ZodiakList from "../quiz/zodiakList"
import Carousel from "../forAll/Carousel"
import FormInputReactMask from "../quiz/form-component/FormInputReactMask"
import {getCookie} from "../../functions/Cookie";
import FormInputLangLoves from "../quiz/form-component/FormInputLangLoves";
import FormInputLangLoves2 from "../quiz/form-component/FormInputLangLoves2";
import {Data} from "../../types/data";


interface ImmageArr {
    o_img1: File | null;
    o_img2: File | null;
    o_img3: File | null;
    o_img4: File | null
}

const Form = styled('form')({
    // display: 'none',
    textAlign: 'left',
    maxWidth: '45%',
    '& > div': {
        border: '1px solid rgb(80, 61, 122)'
    },
    '& > div > label.MuiFormLabel-filled': {
        display: 'none'
    },
    '& > div > label.css-u9osun': {
        display: 'none'
    },
    '& > div > label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        display: 'none'
    },
    marginLeft: '25%',
});

const Input = styled('input')({
    display: 'none',
});

interface TicketType {
    description: string,
    status: boolean,
    msg: string,
}
const TicketAdd: FC = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [submitData, { data, error, isError, isSuccess }] = useSubmitDataWithRerenderMutation()



    const { register, handleSubmit, watch, formState: { errors }, control, setValue } = useForm<TicketType>({
        defaultValues: {
            description: "",
        }
    });

    useEffect(() => {
        if(isSuccess) {
            // @ts-ignore
            if(typeof(data.status) === "boolean"){
                // @ts-ignore
                enqueueSnackbar(data.msg, {variant: (data.status === true ? 'success' : 'error') });
            }
        }
    }, [data]);
    const onSubmit: SubmitHandler<TicketType> = data => {
        let newData = data;
        let fd = new FormData()
        fd.append('data', JSON.stringify(newData))
        submitData({ name: 'ticket.php', data: fd })
    };

    return (
        <>
            <h1>Написать разработчику</h1>
            <Form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'left', maxWidth: 'calc(100% - 30px)', margin: '0'}}>
                <T sx={{fontSize: '20px'}}>Опишите как <b>можно подробнее проблему</b> или предложение доработки, уточните <b>где&nbsp;находится</b> тот или иной элемент, на&nbsp;какой странице, Вы&nbsp;можете вставить ссылку в&nbsp;сообщение на&nbsp;страницу с&nbsp;проблемой чтобы разработчику было проще воспроизвести и&nbsp;протестировать ситуацию. Спасибо.</T>
                <FormInputTextAria
                    name="description"
                    label="Подробное описание проблемы"
                    control={control}
                    style={{fontSize: '20px'}}
                />
                <Button type="submit" variant="contained" color="secondary" sx={{fontSize: "20px"}}>Отправить сообщение</Button>
            </Form>
        </>
    )
}

export default TicketAdd
