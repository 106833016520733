import { Button } from "@mui/material"
import { useSnackbar } from "notistack"
import { FC } from "react"
import { useAppDispatch } from "../../hooks/hooks"
import { useGetApiQuery, useSubmitDataMutation } from "../../services/goroskop"
import T from "../quiz/OnlyText"
import EnhancedTable from "./constituents/Table"
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { getCookie } from '../../functions/Cookie';

const Lines: FC = () => {

    // const { path, date } = useAppSelector(state => state.adminSlice)
    const dispastch = useAppDispatch()

    const { data, error, isLoading } = useGetApiQuery('lines.php')
    const [submitData, { }] = useSubmitDataMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const sendExport = (id?: string, name?: any) => {

        let fD = new FormData();

        id && fD.append('val', id)

        switch (typeof name) {
            case 'object':
                fD.append('obj', name)
                break;
            case 'string':
                fD.append('export', name)
                break;
        }
        submitData({ name: 'export.php', data: fD })

        enqueueSnackbar('Данные успешно обновлены', {
            variant: 'success',
        })
    }

    const role = String(getCookie('role'));

    function printExport(role: string){
        if(role == 'ok_admin') {
            return(
                <>
                    <T>Экспортировать таблицу с 1 по 9 модератора:</T>
                    <Button variant="contained" size="large" onClick={() =>
                        sendExport(undefined, 'line2')}>
                        Экспортировать
                    </Button>
                </>
            );
        }
    }


    // let newDate = date ?
    //     data?.filter(obj => obj['next_contact_date'] && +new Date(obj['next_contact_date']) <= +new Date(date))
    //     : false
    return(
        <>
            {error && <h1>oops, er: {error}</h1>}
            {/* <TextField
                id="date-picker"
                label='Выберите дату'
                type="date"
                sx={{ width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                value={date}
                onChange={(e) => dispastch(restrictDate(e.target.value))}
            /> */}
            {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            {data && (
                <>
                    {role == 'ok_admin' ?
                        <>
                        <Toolbar sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 },}}>
                            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                                {'Менеджер отдела обслуживания'}{' '}
                                <Link to={`../profile/569`}><EditIcon /></Link>
                            </Typography>
                        </Toolbar>
                        <Toolbar sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 },}}>
                            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                                {'Руководитель продаж'}{' '}
                                <Link to={`../profile/102`}><EditIcon /></Link>
                            </Typography>
                        </Toolbar>
                        <hr />
                        </>
                    : null }
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '1')} nameOfTable='Модератор 1' modInfo={data?.filter(obj => obj['id'] === '103')} exId={103} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '2')} nameOfTable='Модератор 2' modInfo={data?.filter(obj => obj['id'] === '104')} exId={104} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '3')} nameOfTable='Модератор 3' modInfo={data?.filter(obj => obj['id'] === '106')} exId={106} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '4')} nameOfTable='Модератор 4' modInfo={data?.filter(obj => obj['id'] === '107')} exId={107} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '5')} nameOfTable='Модератор 5' modInfo={data?.filter(obj => obj['id'] === '108')} exId={108} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '6')} nameOfTable='Модератор 6' modInfo={data?.filter(obj => obj['id'] === '109')} exId={109} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '7')} nameOfTable='Модератор 7' modInfo={data?.filter(obj => obj['id'] === '110')} exId={110} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '8')} nameOfTable='Модератор 8' modInfo={data?.filter(obj => obj['id'] === '111')} exId={111} />
                    <EnhancedTable data={data?.filter(obj => obj['status'] === '9')} nameOfTable='Модератор 9' modInfo={data?.filter(obj => obj['id'] === '112')} exId={112} />
                </>
            )}

            {printExport(role)}
        </>
    )
}

export default Lines