import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    TextField,
    Typography,
    Collapse,
    FormControlLabel, Checkbox
} from "@mui/material";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { increment, decrement, setStep } from "../reducers/quizSlice";
import  { ReactInputMask } from 'react-input-mask';
import {  Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { QuizType } from "../types/quiz";
import FormInputText from "./quiz/form-component/FormInputText";
import FormInputNumber from "./quiz/form-component/FormInputNumber";
import FormInputDropdown from "./quiz/form-component/FormInputDropDown";
import FormInputTextAria from "./quiz/form-component/FormInputTextArea";
import T from "./quiz/OnlyText";
import FormInputReactMask from "./quiz/form-component/FormInputReactMask";
import QuizPasswords from "./quiz/form-component/quizPasswords";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { useSnackbar } from "notistack";
import FormInputRadioButton from "./quiz/form-component/FormInputRadioButtons";
import QuizButton from "./quiz/form-component/quizButton";
import FormInputDate from "./quiz/form-component/FormInputDate";
import FormInputDateNEW from "./quiz/form-component/FormInputDateNEW";
import {useSubmitDataMutation, useSubmitDataWithRerenderMutation} from "../services/goroskop";
import { purple, red } from "@mui/material/colors";
import { styled } from "@mui/system";
import ScrollDialog from "./quiz/Dialog";
import Pass from "./quiz/Pass";
import Email from "./quiz/Email";
import { Link } from "react-router-dom";
import {FormInputUniversalText} from "./quiz/form-component/FormInputUniversalText";
import ZodiakList from "./quiz/zodiakList";
import BirthyearList from "./quiz/birthyearList";
import regionCodes from "./forAll/regions";
import genderList from "./forAll/genders";
import '../modal.css';
import '../form.css';
import AgreementPopUp from './quiz/agreement';
import FormInputLangLoves from "./quiz/form-component/FormInputLangLoves";
import FormInputLangLoves2 from "./quiz/form-component/FormInputLangLoves2"; // Заполняя заявку, вы соглашаетесь на
import FormInputRangeSlider from "./quiz/form-component/FormInputRangeSlider"; // Заполняя заявку, вы соглашаетесь на

import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import dayjs, { Dayjs } from 'dayjs';

import 'dayjs/locale/ru' // import locale
dayjs.locale('ru') // use locale

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Data} from "../types/data";
import {MainState} from "../reducers/mainSlice";
import {setCookie} from "../functions/Cookie";
// import { useYMaps } from '@pbe/react-yandex-maps';
// const ymaps = useYMaps(['Map']);
// var geocoder = require('local-reverse-postalcode');

import { getUA } from 'react-device-detect';
import {getFamilyStatusDefault, getFamilyStatutes} from "../functions/printInfo";
import {getLanglove2Access} from "../functions/printInfo";
import {type} from "os";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');

const imgFields:any[] = ['o_img1','o_img2','o_img3','o_img4'];
const imgInputIds = ['o_img1', 'o_img2', 'o_img3', 'o_img4'];
const Input = styled('input')({
    display: 'none',
});

interface ImmageArr {
    o_img1: File | string | null;
    o_img2: File | string | null;
    o_img3: File | string | null;
    o_img4: File | string | null
}
const Form = styled('form')({});
/*const Form = styled('form')({
    // border: '1px dashed grey',
    textAlign: 'center',
    // width: '800px',
    backgroundColor: 'unset',
    minHeight: "700px",
    position: 'relative',
    borderRadius: '10px',
    '& > div': {
        border: '1px solid rgb(80, 61, 122)',
        borderRadius: '8px',
    },
    '& > div > label.MuiFormLabel-filled': {
        display: 'none'
    },
    '& > div > label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        display: 'none'
    },

});*/

const theme = {
    main: {
        default: 'linear-gradient(to right, #05b5cd 0%,#02d2d2 100%)',
        hover: 'linear-gradient(to right, #059EB2 0%,#01B7B7 100%)',
    },
};

// Regions: read: https://www.autonews.ru/news/633febdb9a79473e43223d37#p2

const email = Date.now() + Math.random()

const Quiz = ({ quizRef }: { quizRef?: ((instance: unknown) => void) | React.MutableRefObject<unknown> | null }) => {
    const imgArray = Array(null, null, null, null);
    const [imageArr, setImageArr] = useState<ImmageArr>({
        o_img1: imgArray[0],
        o_img2: imgArray[1],
        o_img3: imgArray[2],
        o_img4: imgArray[3]
    })

    const newString = Object.values(imageArr)
    const [imageNameArr, setimageNameArr] = useState<string[]>([])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [submitData, {data, error}] = useSubmitDataMutation()
    const { value } = useAppSelector((state) => state.quiz);
    const dispatch = useAppDispatch()

    // useEffect(() => {
    //     const storedValue = localStorage.getItem('step');
    //     if (storedValue !== null && parseInt(storedValue) !== value) {
    //         dispatch(setStep(parseInt(storedValue)));
    //     }
    // }, [value, dispatch]);

    useEffect(() => {
        localStorage.setItem('step', value.toString());
    }, [value]);


    const { register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        control,
        setValue,
        watch } = useForm<QuizType>({defaultValues: {
        fav: "",
        fav_date: "",
        fav_modify: "",
        color: "",
        comp: "[]",
        firstname: "",
        gender: "Ж",
        city: "",
        birthday: dayjs('1970-01-01T00:00:00.000'),
        year: "",
        birthyear: "",
        zodiak: "",
        langlove: "",
        langlove2: "",
        familystatus: "",
        phone: "",
        vk: "",
        helptext: "",
        targetsearch: "",
        targetsearchtext: "",
        about: "",
        height: "",
        weight: "",
        inst: "",
        fb: "",
        ok: "",
        email: "",
        pass: "",
        dateofend: dayjs(),
        source: "",
        source_type: "Квиз",
        images: "",
        o_img1: "",
        o_img2: "",
        o_img3: "",
        o_img4: "",
        registermonth_new: "",
        smoke: "",
        children: "",
        birthdaychild1: "",
        birthdaychild2: "",
        birthdaychild3: "",
        birthdaychild4: "",
        lastlove: "",
        lastzodiak: "",
        defer: "0",
        report: "",
        politic: false,
        vip: "0",
        pass2: '',
    }});
    //const checkedPassword = watch('pass2');
    const [checkedPassword, setCheckedPassword] = useState(false);

    // Укажите тип для параметра event
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setCheckedPassword(isChecked);
        if (!isChecked) {
            // Сбрасываем значения полей и ошибки при снятии чекбокса
            setValue('pass', '');
            setValue('pass2', '');
            clearErrors(['pass', 'pass2']);
        }
    };

    const methods = useForm<QuizType>();

    const password = useRef({});
    password.current = watch("pass", "");

    const firstname = useRef({});
    firstname.current = watch("firstname").length;

    const phone = useRef({});
    phone.current = watch("phone").length;

    const activeNext = true;

    const ageCfg = {
        maxDifference: 20,
        minusMan: 20,
        plusMan: 8,
        minusWoman: 8,
        plusWoman: 20,
    };
    let tmpMinRange: number, tmpMaxRange: number,
        age: number, ageStorage: string | null;
    ageStorage = localStorage.getItem('age');
    age = 0;
    if(ageStorage) {
        age = parseInt(ageStorage);
        tmpMinRange = age - ageCfg.maxDifference;
        if(tmpMinRange < 18){
            tmpMinRange = 18;
        }

        tmpMaxRange = age + ageCfg.maxDifference;
        if(tmpMaxRange > 100){
            tmpMaxRange = 100;
        }
    }

    const tmpMinValue = localStorage.getItem('ageFrom')
    const tmpMaxValue = localStorage.getItem('ageUntil')
    const minRangeValue = tmpMinValue !== null ? JSON.parse(tmpMinValue) : 18;
    const maxRangeValue = tmpMaxValue !== null ? JSON.parse(tmpMaxValue) : 100;

    const getVozrast = (date: Dayjs | null) => {
        // return date; - 1971-07-27
        if(date) {
            let dd: string | number = (date.date()),
                mm: string | number = (date.month()) + 1,
                yyyy: string | number = (date.year());

            let today = new Date();
            //@ts-ignore
            let birthDate = new Date(yyyy,mm,dd);

            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        return 0;
    }

    const handleValidation = (showMessage: boolean) => {
        let formIsValid = true;
        let message = '';
        let data = {
            email: watch("email"),
            //pass: watch("pass"),
            //pass2: watch("passwordConfirm")
        }
        //if(data.email && data.email.length > 0 || data.pass && data.pass.length > 0 || data.pass2 && data.pass2.length > 0)
        if (data.email.length == 0) {
            formIsValid = false;
            message = 'Поле email/логин обязательно для заполнения';
        } else if (data.email.length < 4) {
            //  && !data.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            formIsValid = false;
            message = 'Проверьте пожалуйста правильность введеного email / логина';
        }/* { else if (
            ((data?.pass ?? '') !== '' && (data?.pass ?? '').length < 6) ||
            ((data?.pass2 ?? '') !== '' && (data?.pass2 ?? '').length < 6)
        )
            formIsValid = false;
            message = 'Минимальная длина пароля - 6 символов';
        } else if (data.pass.length >= 6 && data.pass2 !== data.pass) {
            formIsValid = false;
            message = 'Пароли не совпадают';
        }*/
        //console.log('showMessage',showMessage);
        //console.log('formIsValid',formIsValid);
        if(showMessage){
            return message;
        }
        return formIsValid;
    }

    let navigate = useNavigate();
    const authMe = (data: {
        Account: Data,
        whoIs: string,
        token: string,
        status: boolean,
        msg: string,
        type: string,
        gender: number,
        color: number,
        use_coins: boolean
    }) =>{
        if(data.status === true) {
            const whoIs = data.whoIs

            if (whoIs !== '[]' && data.token) {
                let authData: MainState = {
                    Account: data.Account,
                    role: data.whoIs,
                    easyRole: "unlogined",
                    token: data.token,
                    color: data.color,
                    use_coins: data.use_coins
                }

                setCookie('menu', (data.color).toString(), 7)

                if(data.gender) {
                    setCookie('gender', (data.gender).toString(), 7)
                }
                setCookie('role', whoIs, 7)
                setCookie('token', authData.token, 7)
                setCookie('update_time', String(new Date().getTime()), 7)

                if(typeof(data.use_coins) === 'boolean') {
                    if(data.use_coins) {
                        setCookie('use_coins', '1', 7)
                    }else{
                        setCookie('use_coins', '0', 7)
                    }
                }

                //@ts-ignore
                if(typeof(data.Account.tariff_coins) !== 'undefined'){
                    //@ts-ignore
                    setCookie('coins',data.Account.tariff_coins, 7)
                }

                authData.easyRole = "user";
                //navigate(`../cabinet/${data.Account.id}`, {replace: true})
                navigate(`../cabinet`, {replace: true})
            } else {
                enqueueSnackbar('Ошибка авторизации', {
                    variant: 'error',
                })
            }
        }else{
            if(data.msg) {
                if(data.msg == 'moderator'){
                    enqueueSnackbar('Учетная запись не активна, обратитесь пожалуйста к администрации для ускоренного подтверждения ваших данных.', {
                        variant: 'info'
                    })
                }else {
                    enqueueSnackbar(data.msg, {
                        variant: data.status ? 'success' : 'error'
                    })
                }
            }
        }
    }

    const onSubmit: SubmitHandler<QuizType> = dataQuiz => {
        console.log('Current step:', value);

        let returnMe = true;
        let newdataQuiz = dataQuiz;
        /*if (value > 6 && value < 21) {
            newdataQuiz.color = 'red'
        } else if (value === 21) {
            newdataQuiz.color = 'yellow'
        } else {
            newdataQuiz.color = 'white'
        }*/
        newdataQuiz.color = 'white'
        newdataQuiz.images = imageNameArr.join(',');

        if((dataQuiz.firstname).length < 3){
            returnMe = false;
            enqueueSnackbar('Как вас зовут?', {variant: 'info'})
            return;
        }else{
            let fioTest = (dataQuiz.firstname).split(' ');
            let fioPass = true;
            if(fioTest.length < 2){
                fioPass = false
            }else{
                if (fioTest[0].length < 2) {
                    fioPass = false;
                }
                if (fioTest[1].length < 2) {
                    fioPass = false;
                }
            }
            if(!fioPass) {
                enqueueSnackbar('Как вас зовут?', {variant: 'info'})
                return;
            }
        }
        let shortQuiz = localStorage.getItem('quiz_full') !== '1';
        switch(value){
            case 0:
                if(newdataQuiz.gender && newdataQuiz.gender !== '') {
                    localStorage.setItem('gender', newdataQuiz.gender);
                }
                break;
            case 1: // устанавливаем диапазон желаемого возраста по умолчанию
                if(newdataQuiz.gender && newdataQuiz.gender !== '') {
                    localStorage.setItem('gender', newdataQuiz.gender);
                }
                if(dataQuiz.birthday){
                    //@ts-ignore
                    let year: number = (dataQuiz.birthday).year() + 1;
                    let currYear = new Date().getFullYear();
                    if( (year > 1900) && (year <= currYear) ){
                        //@ts-ignore
                        let age = getVozrast(dataQuiz.birthday);
                        if(age >= 0) {
                            localStorage.setItem('age', age.toString());
                            let agesMinus: number = 20;
                            let agesPlus: number = 20;
                            let gender = localStorage.getItem('gender');
                            switch (gender){
                                case 'М':
                                    agesMinus = ageCfg.minusMan;
                                    agesPlus = ageCfg.plusMan;
                                    break;
                                case 'Ж':
                                    agesMinus = ageCfg.minusWoman;
                                    agesPlus = ageCfg.plusWoman;
                                    break;
                                default:
                                    agesMinus = 20;
                                    agesPlus = 20;
                                    break;
                            }
                            let ageUntil:string = '100';
                            let ageUntilTmp = (age + agesPlus);
                            if (ageUntilTmp <= 100) {
                                ageUntil = ageUntilTmp.toString();
                            }
                            localStorage.setItem('ageUntil', ageUntil);

                            let ageFrom:string = '18';
                            let ageFromTmp = (age - agesMinus);
                            if(ageFromTmp >= 18){
                                ageFrom = ageFromTmp.toString();
                            }
                            localStorage.setItem('ageFrom', ageFrom);
                        }
                    }
                }
                break;
            case 6:
            case 7:
                switch(value) {
                    case 6:
                        console.log('shortQuiz', shortQuiz)
                        if (shortQuiz && (dataQuiz.phone).length < 17) {
                            enqueueSnackbar('Необходимо заполнить телефон', {variant: 'info'})
                            console.log('521')
                            return;
                        }
                        break;
                    case 7:
                        console.log('shortQuiz', shortQuiz)
                        if (!shortQuiz && (dataQuiz.phone).length < 17) {
                            enqueueSnackbar('Необходимо заполнить телефон', {variant: 'info'})
                            console.log('529')
                            return;
                        }
                        break;
                }
            break;
            case 8:
            case 16:
                // setImageArr
                let imageError = true;
                if(imageArr.o_img1 !== null){
                    imageError = false;
                }else if(imageArr.o_img2 !== null){
                    imageError = false;
                }else if(imageArr.o_img3 !== null){
                    imageError = false;
                }else if(imageArr.o_img4 !== null) {
                    imageError = false;
                }
                switch(value) {
                    case 8:
                        //dataQuiz.img
                        if (shortQuiz && imageError) {
                            enqueueSnackbar('Необходимо добавить фотографию', {variant: 'warning'})
                            return;
                        }
                        break;
                    case 16:
                        if (!shortQuiz && imageError) {
                            enqueueSnackbar('Необходимо добавить фотографию', {variant: 'warning'})
                            return;
                        }
                        break;
                }
                break;
            case 17:
                if(newdataQuiz.children == 'Да'){
                    localStorage.setItem('children', 'Y');
                }else{
                    localStorage.setItem('children', 'N');
                }
                break;
            case 18:
                step18children(true, dataQuiz.children);
                break;
            case 9:
            case 21:
                console.log('EMAIL LOGIN',value);
                console.log({
                    short: (shortQuiz && value == 9),
                    long: (!shortQuiz && value == 21)
                });
                if(
                    (shortQuiz && value == 9) ||
                    (!shortQuiz && value == 21)
                ) {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const loginRegex = /^[a-zA-Z0-9_]{3,}$/;

                    if (emailRegex.test(dataQuiz.email)) {
                        // return true; // Это валидный email
                    } else if (loginRegex.test(dataQuiz.email)) {
                        // return true; // Это валидный логин
                    } else {
                        enqueueSnackbar('Введите корректный логин или email', {variant: 'info'})
                        return;
                    }

                    //setValue('pass', getValues('pass'))
                    //if (dataQuiz.email.length == 0 || (dataQuiz.email.length < 3)) {
                        /*if (dataQuiz.email.length == 0) {
                            enqueueSnackbar('Email обязателен для авторизации', {variant: 'info'})
                        } else if (dataQuiz.pass.length < 6) {
                            enqueueSnackbar('Пароль обязателен для авторизации', {variant: 'info'})
                        } else if (dataQuiz.passwordConfirm !== dataQuiz.pass) {
                            enqueueSnackbar('Пароли не свопадают', {variant: 'info'})
                        }*/
                        //dispatch(decrement());
                    //    enqueueSnackbar('Слишком короткий Email / Логин', {variant: 'info'})
                    //    return;
                    //}
                }
                break;
        }
        if(returnMe){
            let fd = new FormData()

            const tmpAgeFrom = localStorage.getItem('ageFrom')
            const tmpAgeUntil = localStorage.getItem('ageUntil')
            newdataQuiz.ageFrom = tmpAgeFrom !== null ? JSON.parse(tmpAgeFrom) : 18;
            newdataQuiz.ageUntil = tmpAgeUntil !== null ? JSON.parse(tmpAgeUntil) : 100;

            if(newdataQuiz.birthday) {
                try {
                    //@ts-ignore
                    newdataQuiz._birthday = (newdataQuiz.birthday.format('DD.MM.YYYY'));
                }catch (e){
                    newdataQuiz._birthday = '';
                }
            }

            if(newdataQuiz.dateofend) {
                try {
                    //@ts-ignore
                    newdataQuiz._dateofend = (newdataQuiz.birthdaydateofend.format('DD.MM.YYYY'));
                }catch (e){
                    newdataQuiz._dateofend = '';
                }
            }

            fd.append('data', JSON.stringify(newdataQuiz))
            fd.append('step', value.toString())
            fd.append('email', email.toString())
            fd.append('useragent', getUA);
            let shortQuiz = localStorage.getItem('quiz_full');
            if(shortQuiz !== null){
                fd.append('full_quiz', shortQuiz)
            }
            if(shortQuiz !== '1'){
                //console.log('sendform value==6:',value);
                if (value > 5) {
                    submitData({name: 'quiz.php', data: fd}).then((answer: any) => {
                        if (answer.data && answer.data.Account) {
                            authMe(answer.data);
                        }
                    })
                }
            }else {
                if (value > 6) {
                    submitData({name: 'quiz.php', data: fd}).then((answer: any) => {
                        if (answer.data && answer.data.Account) {
                            authMe(answer.data);
                        }
                    })
                }
            }
            if (error) {
                console.log({error: error, data: newdataQuiz});
            }
            let res = data;
            try {
                /* @ts-ignore */
                if (typeof (res.result) !== 'undefined' && res.msg !== 'undefined') {
                    /* @ts-ignore */
                    if (res.result == true) {
                        /* @ts-ignore */
                        if (res.msg && res.msg != 'ok update') {
                            enqueueSnackbar(
                                /* @ts-ignore */
                                res.msg, {
                                    variant: 'success',
                                })
                        }
                    } else { // @ts-ignore
                        /* @ts-ignore */
                        if (res.msg) {
                            enqueueSnackbar(
                                /* @ts-ignore */
                                res.msg, {
                                    variant: 'error',
                                })
                        }
                    }
                }
            } catch (e) {}
            dispatch(increment());
        }
    };

    const step18children = (route: boolean, children:string | boolean) => {
        if((route && value == 18) || (!route && value == 20)) {
            let child = false;
            if (children !== false) {
                if (children !== 'Да') {
                    localStorage.setItem('quiz_child', '0');
                    child = false;
                } else {
                    localStorage.setItem('quiz_child', '1');
                    child = true;
                }
            } else {
                let childData = localStorage.getItem('quiz_child')
                if (childData !== '0') {
                    child = true;
                } else {
                    child = false;
                }
            }
            if (!child) {
                localStorage.setItem('quiz_child', '0');
                setStep(value + 2)
                if (route) {
                    dispatch(increment());
                } else {
                    dispatch(decrement());
                }
            }
        }
    }

    useEffect(() => {
        // @ts-ignore
        if (data && data.files) {
            // @ts-ignore
            setImageArr(data.files);
            // @ts-ignore
            imgFields.forEach((field) => {
                // @ts-ignore
                imageNameArr[Object.keys(data.files).indexOf(field)] = data.files[field] ? data.files[field] : 'null'
            })
        }
    });

    // Извлекаем значение из localStorage при инициализации состояния
    const [isCheckedShort, setIsCheckedShort] = useState(() => {
        // По умолчанию галочка установлена (true)
        return localStorage.getItem('quiz_full') !== '1';
    });
    const [maxSteps, setMaxSteps] = useState<number>(1);


    useEffect(() => {
        // Обновляем localStorage при изменении значения isChecked
        localStorage.setItem('quiz_full', isCheckedShort ? '0' : '1');
        if(isCheckedShort){ // Короткая версия
            setMaxSteps(10);
        }else{
            setMaxSteps(22)
        }
    }, [isCheckedShort]);

    const handleChangeShort = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckedShort(event.target.checked);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let fd = new FormData()

        const file = e.target.files ? e.target.files[0] : 'null'
        const fileName = e.target.files ? e.target.files[0]?.name : 'null'
        const inputName = e.currentTarget.name

        // needed:
        imageNameArr[Object.keys(imageArr).indexOf(e.target.name)] = e.target.files ? e.target.files[0].name : 'null'

        const fR = new FileReader()

        e.target.files && fR.readAsDataURL(e.target.files[0])

        // fR.onloadend = () => {
        //     setImageArrTmp({ ...imageArrTmp, [e.target.name]: fR.result })
        //     console.log('imageArrTmp',imageArrTmp);
        // }

        setValue('images', imageNameArr.join(','))

        fd.append(e.target.name, file)
        //fd.append('user_email', email.toString())

        fd.append('images', imageNameArr.join(','))

        submitData({ name: 'updatePhoto.php', data: fd })
            .then((answer: any) => {
                if (answer.data) {
                    if(answer.data.result == true){
                        if(answer.data.msg){
                            enqueueSnackbar(answer.data.msg, {variant: 'info'});
                        }else{
                            enqueueSnackbar('Ошибка', {variant: 'info'});
                        }
                    }else{
                        if(answer.data.msg){
                            enqueueSnackbar(answer.data.msg, {variant: 'error',});
                        }else{
                            enqueueSnackbar('Ошибка', {variant: 'error'});
                        }
                    }
                }else{
                    enqueueSnackbar('Ошибка', {variant: 'error'});
                }
            })
    }

    const deleteImage = (nameImg: number) => {
        setImageArr({ ...imageArr, [Object.keys(imageArr)[nameImg]]: null })
        newString[nameImg] =  null
    }

    const step1 = () => {
        return (
            <form>
                <T>Имя и Фамилия как в Vkontakte</T>
                <FormInputUniversalText
                    // label='Имя и фамилия'
                    type='text'
                    name='firstname'
                    placeholder={'Имя и фамилия'}
                    error={!!errors.firstname?.type}
                    rules={{
                        min: 3,
                        validate: (value: string): boolean => {
                            const words = value.trim().split(/\s+/).filter((word: string) => word.length > 0);
                            return words.length >= 2 && words.every((word: string) => word.length >= 2);
                            //return (value.length >= 3)
                        }
                    }}
                    control={control}
                    focused
                    className={`form-control ${errors.firstname || firstname.current < 3 ? 'is-invalid' : ''}`}
                    sx={{borderRadius: '5px'}}
                />

                <T sx={{margin:0}}>Ваш пол</T>
                <FormInputDropdown
                    control={control}
                    name='gender'
                    label="Выберите пол"
                    options={genderList}
                    defaultValue={''}
                    forceValidate={true}
                />

                <T>Ваш регион</T>
                <FormInputDropdown
                    control={control}
                    name='city'
                    label="Выберите регион"
                    options={regionCodes}
                    defaultValue={''}
                    forceValidate={true}
                />
                {/*<div
                            className="invalid-feedback"
                            style={
                                ((errors.firstname?.type) || (firstname.current < 3))
                                    ? {}
                                    : {display: "none"}
                            }
                        >{ ((errors.firstname?.type) || (firstname.current < 3)) ? 'Поле имя - обязательно для заполнения' : ''}</div>
                        */}
                {((errors.firstname?.type) || (firstname.current < 3)) ?
                    <Alert severity="error" sx={{
                        margin: '20px 0 0 0',
                        textAlign: 'left'
                    }}>
                        Поле имя - обязательно для заполнения
                    </Alert>
                    : '' }

                {/*<T>Продолжая, вы соглашаетесь на <a href={'#'} onClick={
                            Popup.alert('I am alert, nice to meet you')
                        }>обработку персональных данных</a>.</T>*/}
                <AgreementPopUp welcomeText='Заполняя заявку, вы соглашаетесь на'/>
                {/*
                        <T>Введите код регионa. Если Вы не занете код Вашего региона нажмите:</T>
                        <ScrollDialog >
                            <CardMedia
                                component="img"
                                height="90%"
                                image={`/images/region_code.jpg`}
                                alt={'Код региона Россия'}
                            />
                        </ScrollDialog>*/}
            </form>
        )
    }
    const step2 = () => {
        return(
            <>
                <T>Дата вашего рождения?</T>
                <FormInputDateNEW
                    control={control}
                    // label='Дата рождения'
                    name="birthday"
                    validate18={true}
                />
                <div style={{marginLeft: '-21px'}}>
                <FormControlLabel
                    sx={{margin: '15px 0 0 0'}}
                    control={
                        <Checkbox
                            checked={isCheckedShort}
                            onChange={handleChangeShort}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Заполнить простую анкету"
                />
                </div>
            </>
        )
    }
    const step3 = () => {
        return(
            <>
                <T>
                    В год какого животного вы родились?
                    Если вы не знаете в год какого животного вы родились,
                    то дата начала и конец вашего года указаны :
                </T>
                <ScrollDialog >
                    <CardMedia
                        component="img"
                        height="90%"
                        image={`/images/anim_year.jpg`}
                        alt={'Знаки зодиака по дате'}
                    />
                </ScrollDialog>
                <BirthyearList name="birthyear" forceValidate={true} control={control} setValue={setValue} />

            </>
        )
    }
    const step4 = () => {
        return (
            <>
                <T>
                    Кто вы по знаку зодиака? Если не знаете свой знак зодиака,
                    то посмотрите подсказку:
                </T>
                <ScrollDialog >
                    <CardMedia
                        component="img"
                        height="90%"
                        image={`/images/zodiak_year.jpg`}
                        alt={'Знаки зодиака по дате'}
                    />
                </ScrollDialog>
                <ZodiakList forceValidate={true} name="zodiak" control={control} setValue={setValue} />
            </>
        )
    }
    const step5 = () => {
        return (
            <>
                <T>Выберете пожалуйста 2 своих психологических языка любви.
                    Это то, как вы понимаете, что вас любят,
                    и вы готовы так же выражать свою любовь.
                    Языки любви посмотреть:
                </T>
                <ScrollDialog >
                    <CardMedia
                        component="img"
                        height="90%"
                        image={`/images/lang_love.jpg`}
                        alt={'Языки любви'}
                    />
                </ScrollDialog>
                {/*<T>Основной язык любви:</T>
                        <FormInputRadioButton
                            control={control} name='langlove'
                            options={['Одобрение', 'Время', 'Помощь', 'Подарки', ]} // Первое нельзя помощь, а потом время, сначала время а потом помощь
                        />
                        <T>Второстепенный язык любви:</T>
                        <FormInputRadioButton
                            control={control} name='langlove2'
                            options={['Одобрение', 'Прикосновения', 'Время', 'Помощь', 'Подарки', ]}
                        />*/}
                <T>Основной язык любви</T>
                <FormInputLangLoves
                    control={control}
                    name='langlove'
                    forceValidate={true}
                />
            </>
        )
    }
    const step6 = () => {
        return (
            <>
                <T>Выберете пожалуйста 2 своих психологических языка любви.
                    Это то, как вы понимаете, что вас любят,
                    и вы готовы так же выражать свою любовь.
                    Языки любви посмотреть:
                </T>
                <ScrollDialog >
                    <CardMedia
                        component="img"
                        height="90%"
                        image={`/images/lang_love.jpg`}
                        alt={'Языки любви'}
                    />
                </ScrollDialog>
                <T>Второстепенный язык любви</T>

                <FormInputLangLoves2
                    control={control}
                    name='langlove2'
                    forceupdate={false}
                    forceValidate={true}
                />
            </>
        )
    }
    const step7 = () => {
        return (
            <>
                <T>Ваше семейное положение</T>
                <FormInputRadioButton
                    control={control}
                    name='familystatus'
                    options={getFamilyStatutes()}
                    defaultValue={getFamilyStatusDefault()}
                />
            </>
        )
    }
    const step8 = () => {
        return (
            <>
                <T>Введите ваш номер телефона</T>
                <FormInputReactMask
                    control={control}
                    name='phone'
                    /*error={!!errors.firstname?.type}*/
                    rules={{
                        required: true,
                        min: 17,
                        validate: (value: any) => {
                            return (value.length >= 3)
                        }
                    }}
                    className={`form-control ${errors.phone || phone.current > 3 ? 'is-invalid' : ''}`}
                />

                {/*<FormInputUniversalText
                            label='Телефон'
                            type='text'
                            name='phone'
                            control={control}
                            focused
                            className={`form-control ${errors.firstname || firstname.current > 3 ? 'is-invalid' : ''}`}
                        />*/}
                {/*<div
                            className="invalid-feedback"
                            style={
                                ((errors.phone?.type) || (phone.current < 17))
                                    ? {}
                                    : {display: "none"}
                            }
                        >{ (phone.current < 17) ? 'Поле телефон - обязательно для заполнения' : ''}</div>*/}
                {((errors.phone?.type) || (phone.current < 17)) ?
                    <Alert severity="error" sx={{
                        margin: '20px 0 0 0',
                        textAlign: 'left'
                    }}>
                        Поле телефон - обязательно для заполнения
                    </Alert>
                    : '' }
            </>
        )
    }
    const step9 = () => {
        return (
            <>
                <T>Введите ссылку на вашу страничку Vkontakte<br/>
                    [<a
                        style={{ color: red[800] }}
                        title={'Как скопировать ссылку на вк'}
                        target={'_blank'}
                        rel={'nofollow'}
                        href={'https://vk.com/faq18366'}>Инструкция</a>]
                </T>
                <T>Кликни что бы подставить пример: <a href={'#'} style={{ color: red[800] }} onClick={(e) => {
                    setValue('vk', 'https://vk.com/goroskoper2000')
                }}>https://vk.com/goroskoper2000</a></T>
                <FormInputText control={control} label='Ссылка вконтакте' name="vk" />
            </>
        )
    }
    const step10 = () => {
        return (
            <>
                <T>Чем я&nbsp;могу Вам помочь? Какой у&nbsp;Вас запрос? Кого Вы&nbsp;хотите найти? Просьба дать максимально развернутый ответ.</T>
                {/*<Alert severity="warning" sx={{
                            margin: '20px 0 0 0',
                            textAlign: 'left'
                        }}>
                            Данные на этом шаге в анкете отображены не будут
                        </Alert>*/}
                <FormInputTextAria
                    control={control}
                    label='Чем я Вам могу помочь?'
                    name='helptext'
                />
                <FormInputRangeSlider
                    control={control}
                    labelString='Выберите желаемый возраст партнера'
                    name='agesRange'
                    minRange={tmpMinRange}
                    maxRange={tmpMaxRange}
                    minValue={minRangeValue}
                    maxValue={maxRangeValue}
                    stepRange={1}
                    yearStep={true}
                />
                <Alert severity="info" sx={{
                    margin: '20px 0 0 0',
                    textAlign: 'left'
                }}>
                    Некоторые варианты <b>запрещены</b> для выбора из&nbsp;рассчета <u>плохой&nbsp;совместимости</u> по&nbsp;дате рождения. По&nbsp;умолчанию установлен <b>рекомендуемый</b> диапазон возрастов.<br />
                </Alert>
            </>
        )
    }
    const step11 = () => {
        return (
            <>
                <T>Вид поиска</T>
                <FormInputRadioButton
                    control={control}
                    name='targetsearch'
                    options={['Активный', 'Пассивный', 'Консультация', 'Встреча']}
                />
                <FormInputTextAria
                    control={control}
                    label='Опишите, что еще вы хотели бы учесть при поиске партнера...'
                    name="targetsearchtext"
                />
            </>
        )
    }
    const step12 = () => {
        return (
            <>
                <T>Расскажите, пожалуйста немного о&nbsp;себе.
                    Ваше Образование? Сфера деятельности? Должность?
                    Качества которыми&nbsp;Вы обладаете? Ваши Увлечения? Хобби? Достижения?
                    Напишите пожалуйста Ваш регион проживания и&nbsp;населённый пункт в&nbsp;котором вы&nbsp;проживаете.
                </T>
                <FormInputTextAria
                    name="about"
                    label='Расскажите о себе'
                    control={control}
                />
            </>
        )
    }
    const step13 = () => {
        return (
            <>
                <T>Введите ваш рост, см</T>
                <FormInputNumber
                    control={control}
                    label='Ваш рост'
                    name="height"
                />
                <T>Введите ваш вес, кг</T>
                <FormInputNumber
                    control={control}
                    label='Ваш вес'
                    name="weight"
                />
            </>
        )
    }
    const step14 = () => {
        return (
            <>
                <Typography variant="subtitle1" component="p">
                    Ваши дополнительные социальные сети
                </Typography>
                <T>Ссылка на Instagram</T>
                <FormInputText control={control} label='Ссылка на Instagram' name="inst" />
                <T>Ссылка на Facebook</T>
                <FormInputText control={control} label='Ссылка на Facebook' name="fb" />
                <T>Ссылка на Одноклассники</T>
                <FormInputText control={control} label='Ссылка на Одноклассники' name="ok" />
            </>
        )
    }
    const step15 = () => {
        return (
            <>
                <Typography variant="subtitle1" component="p">
                    Дата окончания отношений
                </Typography>
                <FormInputDateNEW
                    control={control}
                    // label='Дата рождения'
                    name="dateofend"
                />
            </>
        )
        //<FormInputDate control={control} label='Дата окончания отношений' name="dateofend" />
    }
    const step16 = () => {
        return (
            <>
                <Typography variant="subtitle1" component="p">
                    Откуда узнали обо мне
                </Typography>
                <FormInputText control={control} label='Откуда узнали обо мне' name="source" />
            </>
        )
    }
    const step17 = () => {
        return (
            <>
                <Typography variant="subtitle1" component="p">
                    Прикрепите свои лучшие 3-4 фото
                </Typography>
                <T sx={{width: '100%', position: 'relative'}}>
                    {
                        Object.values(imageArr).map((img, index) => {

                            return (
                                <Card sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0" }} key={index} onClick={() => deleteImage(index)}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={
                                                (img && img !== null && img.length > 0 && img !== 'null')
                                                    ? `/upload/${img}`
                                                    : '/images/noImg.jpg'
                                            }
                                            alt="photo"
                                        />
                                        <CardContent >
                                            <Typography gutterBottom variant="h5" component="div" >
                                                Delete
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )})
                    }
                </T>
                {/*<input {...register('o_img1')} type="file" accept="image/jpg, image/jpeg" onChange={handleChange}/>*/}
                <label htmlFor="contained-button-file1">
                    {/*imgInputIds.forEach(inputId => {})*/}
                    <Input {...register('o_img1')} onChange={handleChange} id="contained-button-file1" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 1
                    </Button>
                </label>
                <label htmlFor="contained-button-file2">
                    <Input {...register('o_img2')} onChange={handleChange} id="contained-button-file2" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 2
                    </Button>
                </label>
                <label htmlFor="contained-button-file3">
                    <Input {...register('o_img3')} onChange={handleChange} id="contained-button-file3" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 3
                    </Button>
                </label>
                <label htmlFor="contained-button-file4">
                    <Input {...register('o_img4')} onChange={handleChange} id="contained-button-file4" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 4
                    </Button>
                </label>
                {/* images */}
            </>
        )
    }
    const step18 = () => {
        return (
            <>
                <Typography></Typography>
                <T>Курение?</T>
                <FormInputRadioButton
                    control={control}
                    name='smoke'
                    options={['Да', 'Нет', 'Не указано']}
                />
            </>
        )
    }
    const step19 = () => {
        return (
            <>
                <T>Наличие детей</T>
                <FormInputRadioButton
                    control={control}
                    name='children'
                    options={['Да', 'Нет', 'Не указано']}
                />
            </>
        )
    }
    const step20 = () => {
        return (
            <>
                <Typography variant="subtitle1" component="p">
                    Возраст детей и&nbsp;их&nbsp;пол
                </Typography>
                <T>День рождение 1 ребенка</T>
                <FormInputTextAria
                    control={control}
                    name='birthdaychild1'
                    label="День рождение 1 ребенка"
                />
                <T>День рождение 2 ребенка</T>
                <FormInputTextAria
                    control={control}
                    name='birthdaychild2'
                    label="День рождение 2 ребенка"
                />
                <T>День рождение 3 ребенка</T>
                <FormInputTextAria
                    control={control}
                    name='birthdaychild3'
                    label="День рождение 3 ребенка"
                />
                <T>День рождение 4 ребенка</T>
                <FormInputTextAria
                    control={control}
                    name='birthdaychild4'
                    label="День рождение 4 ребенка"
                />
            </>
        )
    }
    const step21 = () => {
        return (
            <>
                <T>Бывший муж (парень) или жена (девушка) в год кого родились?</T>
                <FormInputTextAria
                    control={control}
                    label='Год животного бывшего партнера'
                    name='lastlove'
                />
                <Typography variant="subtitle1" sx={{ fontSize: 12 }} component="p">(если не знаете, то просто укажите год рождения)</Typography>

                <hr/>

                <T>Знак зодиака бывшего мужа или жены?</T>
                <FormInputTextAria
                    control={control}
                    name='lastzodiak'
                    label="Знак зодиака бывшего партнера"
                />
                <Typography variant="subtitle1" sx={{ fontSize: 12 }} component="p">(если не знаете, то просто число и месяц даты рождения)</Typography>
            </>
        )
    }
    const step22 = () => {
        return (
            <>
                <Alert severity="info" sx={{
                    margin: '20px 0 0 0',
                    textAlign: 'left'
                }}>
                    Остался один шаг... для частичного доступа в&nbsp;банк данных <strong>Бюро&nbsp;счастливых&nbsp;знакомств</strong> Вам необходимо придумать логин для входа в&nbsp;систему. Чаще всего в&nbsp;таком случае используют адрес электронного почтового ящика в&nbsp;формате: <strong>V.Gorelov@svat-astrolog.ru</strong> либо запоминающийся логин.<br />
                    <div style={{height: '5px'}}></div>
                    Рекомендуем Вам использовать свой <strong>Email</strong> в&nbsp;качестве <strong>Логина</strong>, чтобы у&nbsp;Вас всегда была оперативная возможность <strong>восстановить доступ</strong> в&nbsp;свой личный кабинет в&nbsp;случае утраты пароля.
                </Alert>
                <T>Введите Email или Логин:</T>
                <FormInputUniversalText
                    type='text'
                    name='email'
                    focused
                    control={control}
                    placeholder={'Введите Email или Логин'}
                    rules={{
                        min: {
                            value: 3,
                            message: 'Минимум 3 символа',
                        },
                        validate: (value: string) => {
                            if (!value) return "Поле обязательно для заполнения";

                            /*const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            const loginRegex = /^[a-zA-Z0-9_]{3,}$/;

                            if (emailRegex.test(value)) {
                                return true; // Это валидный email
                            } else if (loginRegex.test(value)) {
                                return true; // Это валидный логин
                            } else {
                                return false;
                            }*/
                        },
                    }}
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                />

                <div style={{border: 0}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedPassword}
                                onChange={handleCheckboxChange}
                                name="setPasswordManually"
                                color="primary"
                            />
                        }
                        label="Установить пароль вручную"
                    />

                    <Collapse in={checkedPassword} orientation="vertical">
                        <Box>
                            <Controller
                                name="pass"
                                control={control}
                                rules={{
                                    required: checkedPassword ? 'Поле обязательно для заполнения' : false,
                                    minLength: {
                                        value: 6,
                                        message: 'Пароль должен содержать минимум 6 символов'
                                    }
                                }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="pass"
                                        label="Пароль"
                                        name="pass"
                                        type="password"
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (field.value !== getValues('pass2')) {
                                                setError('pass2', { message: 'Пароли не совпадают' });
                                            } else {
                                                clearErrors('pass2');
                                            }
                                        }}
                                        autoComplete="new-password"
                                        error={!!errors.pass}
                                        helperText={errors.pass?.message}
                                        disabled={!checkedPassword}
                                    />
                                )}
                            />

                            <Controller
                                name="pass2"
                                control={control}
                                rules={{
                                    required: checkedPassword ? 'Поле обязательно для заполнения' : false,
                                    minLength: {
                                        value: 6,
                                        message: 'Пароль должен содержать минимум 6 символов'
                                    },
                                    validate: (value) => {
                                        return value === getValues('pass') || 'Пароли не совпадают';
                                    }
                                }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="pass2"
                                        label="Подтвердите пароль"
                                        name="pass2"
                                        type="password"
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (getValues('pass') !== e.target.value) {
                                                setError('pass2', { message: 'Пароли не совпадают' });
                                            } else {
                                                clearErrors('pass2');
                                            }
                                        }}
                                        autoComplete="new-password"
                                        error={!!errors.pass2}
                                        helperText={errors.pass2?.message}
                                        disabled={!checkedPassword}
                                    />
                                )}
                            />
                        </Box>
                    </Collapse>
                </div>
                {/*<T>Введите пароль:</T>
                <FormInputUniversalText
                    type='password'
                    name='pass'
                    error={!!errors.passwordConfirm?.type}
                    rules={{ min: 6}}
                    control={control}
                    focused
                    className={`form-control ${errors.pass ? 'is-invalid' : ''}`}
                />
                <T>Введите пароль повторно:</T>
                <FormInputUniversalText
                    type='password'
                    name='passwordConfirm'
                    error={!!errors.passwordConfirm?.type}
                    rules={{ min: 6, validate: (value: any) => (value === password.current) }}
                    control={control}
                    focused
                    className={`form-control ${errors.passwordConfirm ? 'is-invalid' : ''}`}
                />*/}
                {/*<div
                            className="invalid-feedback"
                            style={
                                (!errors.passwordConfirm?.type)
                                ? {display: "none"}
                                : {}
                            }
                        >{errors.passwordConfirm?.type ? 'Пароли не совпадают' : ''}</div>
                        */}
                {/*(errors.passwordConfirm?.type) ?
                        <Alert severity="error" sx={{
                            margin: '20px 0 0 0',
                            textAlign: 'left'
                        }}>
                            Пароли не совпадают
                        </Alert>
                        : '' */}
                {(!handleValidation(false))?
                    <Alert severity="warning" sx={{
                        margin: '20px 0 0 0',
                        textAlign: 'left'
                    }}>
                        {handleValidation(true)}
                    </Alert>
                    : null}
            </>
        )
    }
    const step23 = () => {
        return (
            <>
                <T>Ваша анкета находится на модерации, в ближайшее время просим связаться с&nbsp;<a href={'https://vk.com/svastro'} target={'_blank'} rel={'nofollow'}>администрацией сообщества</a> для скорейшего прохождения модерации Вашей анкеты.</T>
            </>
        )
    }
    const step24 = () => {
        return (
            <>
                <T>Ваша анкета находится на модерации, в ближайшее время просим связаться с&nbsp;<a href={'https://vk.com/svastro'} target={'_blank'} rel={'nofollow'}>администрацией сообщества</a> для скорейшего прохождения модерации Вашей анкеты.</T>
                <Link to={'/login'}>Войти</Link>
            </>
        )
    }


    const renderForm = () => {
        //console.log('Rendering step:', value);
        let shortQuiz = localStorage.getItem('quiz_full') !== '1';
        if(!shortQuiz) {
            switch (value) {
                case 0:
                    /*if (navigator.geolocation) {
                        // what to do if supported
                        try{
                            navigator.geolocation.getCurrentPosition(
                                (position) => {
                                    //let regionNum = findRegion.numeric_code(position.coords.longitude, position.coords.latitude);
                                    //console.log('findRegion number:',regionNum);
                                    //console.log('country', findRegion.country(position.coords.latitude, position.coords.longitude)); // 'United Kingdom'
                                    //console.log('alpha2_code', findRegion.alpha2_code(position.coords.latitude, position.coords.longitude)); // 'BR'
                                    //console.log('alpha3_code', findRegion.alpha3_code(position.coords.latitude, position.coords.longitude)); // 'ESP'
                                    //console.log('numeric_code', findRegion.numeric_code(position.coords.latitude, position.coords.longitude)); // '250'


                                    console.log('665:',{position: position, latitude: position.coords.latitude, longitude: position.coords.longitude});
                                    //geocoder.lookUp({latitude: position.coords.latitude, longitude: position.coords.longitude}, 5, function(err:any, res:any) {
                                    //    console.log(JSON.stringify(res, null, 2));
                                    //});

                                    //var myReverseGeocoder = YMaps.geocode([61.79,34.36]);


                                    //console.log('nearestCity',nearestCity);
                                    //findRegion
                                    // what to do once we have the position
                                },
                                (error) => {
                                    // display an error if we cant get the users position
                                    console.error('Error getting user location:', error);
                                }
                            );
                        }catch (e) {

                        }
                    }
                    else {
                        // display an error if not supported
                        console.error('Geolocation is not supported by this browser.');
                    }*/
                    return step1();
                case 1:
                    return step2();
                case 2:
                    return step3();
                case 3:
                    return step4();
                case 4:
                    return step5();
                case 5:
                    return step6();
                case 6:
                    return step7(); // Холост или нет
                case 7:
                    return step8();
                case 8:
                    return step9();
                case 9:
                    return step10();
                case 10:
                    return step11();
                case 11:
                    return step12();
                case 12:
                    return step13();
                case 13:
                    return step14();
                case 14:
                    return step15();
                case 15:
                    return step16();
                case 16:
                    return step17(); // фото
                case 17:
                    return step18();
                case 18:
                    return step19();
                case 19:
                    return step20();
                case 20:
                    return step21();
                case 21:
                    return step22();
                case 22:
                    return step23();
                case 23:
                    return step24();
                default:
                    return <h2>Что то не работает...сообщите пожалуйста как это случилось администрации</h2>
            }
        }else{
            switch (value) {
                case 0:
                    return step1();
                case 1:
                    return step2();
                case 2:
                    return step3();
                case 3:
                    return step4();
                case 4:
                    return step5();
                case 5:
                    return step6();
                case 6:
                    return step8();
                case 7:
                    return step10(); // возраст
                case 8:
                    return step17(); // фото
                case 9:
                    return step22(); // email, password
                case 10:
                    return step23();
                case 11:
                    return step24();
                default:
                    return <h2>Что то не работает...сообщите пожалуйста как это случилось администрации</h2>
            }
        }
    }

    return(
        <Box ref={quizRef} sx={{
            textAlign: 'center',
            background: 'unset',
        }}>
            <Form onSubmit={handleSubmit(onSubmit)} >
                <T sx={{ color: '#740BB6', fontSize: '1.75rem', lineHeight: '1.15',
                    /*background: 'linear-gradient(to right, #05B5CD, #02D2D2)',
                    '-webkit-text-fill-color': 'transparent',
                    '-webkit-background-clip': 'text'*/
                }}>Заявка на счастливую личную жизнь</T>
                <T>
                    Шаг <span style={{ color: red[800] }}>{(value+1) <= maxSteps ? (value+1) : maxSteps}&nbsp;/&nbsp;{maxSteps}</span>
                    {/*из  <span style={{ color: red[800] }}> 24</span>*/}
                </T>
                {renderForm()}
                <p className="buttons-container">
                    <QuizButton
                        type="button"
                        onClick={() => {
                            step18children(false, false)
                            dispatch(decrement())
                        }}
                        disabled={value === 0}
                        className="gradient-button left"
                    >Назад</QuizButton>

                    <QuizButton
                        type="submit"
                        onClick={() => handleSubmit(onSubmit)}
                        disabled={value === maxSteps}
                        className="gradient-button right"
                    >Вперед</QuizButton>
                </p>
            </Form>
        </Box>
    )
}



export default forwardRef((props, ref) => <Quiz quizRef={ref} />);
