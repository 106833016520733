import { Checkbox, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

type Options = { value: string; label?: string }

const FormInputCheckbox = ({
    name,
    control,
    defaultValue,
    sx
}: FormInputProps) => {
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const handleSelect = (value: any) => {
        const isPresent = selectedItems.indexOf(value);
        if (isPresent !== -1) {
            const remaining = selectedItems.filter((item: any) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems: any) => [...prevItems, value]);
        }
    };

    let realDefaultValue: boolean = false;
    //@ts-ignore
    if(defaultValue && ((defaultValue.value == '1') || (defaultValue == '1'))){
        realDefaultValue = true;
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={realDefaultValue}
            render={({
                     field: { onChange, value },
                     fieldState: { error },
                 }) => (<Checkbox
                    name={name}
                    onChange={onChange}
                    checked={value}
                />)
            }
        />
    );
    // checked={selectedItems.includes(option.value)}
    // onChange={() => handleSelect(option.value)}
};

export default FormInputCheckbox