import { Badge } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getUnreadMessagesCount } from '../../services/messageService'; // Импортируем сервис для получения количества непрочитанных сообщений

const СhatUnreadMessages = (style: number) => {
    const [unreadCount, setUnreadCount] = useState<number>(0);

    // Функция для получения количества непрочитанных сообщений
    const fetchUnreadMessages = async () => {
        try {
            const count = await getUnreadMessagesCount(); // Получаем количество новых сообщений
            setUnreadCount(count);
        } catch (error) {
            console.error("Error fetching unread messages:", error);
        }
    };

    // Используем эффект, чтобы обновлять количество сообщений при монтировании компонента
    useEffect(() => {
        fetchUnreadMessages();
    }, []);
    if(style === 1){
        return (
            <div style={{display: 'block', position: 'absolute', right: '20px', bottom: '12px'}}>
                {unreadCount > 0 && (
                    <Badge color="primary" badgeContent={unreadCount} max={99} />
                )}
            </div>
        );
    }
    return (
        <div style={{display: 'block', position: 'absolute', right: '7px', bottom: '0px'}}>
            {unreadCount > 0 && (
                <Badge color="primary" badgeContent={unreadCount} max={99} />
            )}
        </div>
    );
};

export default СhatUnreadMessages;
