import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Select, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {
    forceValidate?: boolean
}

const FormInputLangLoves: React.FC<CheBoxProps> = ({
        name,
        control,
        forceValidate
    }) => {
    const options = [
        "Одобрение",
        //"Прикосновения",
        "Время",
        "Помощь",
        "Подарки",
    ];

    // Функция обновления значения в localStorage
    const updateSelection = (event: React.SyntheticEvent<Element, Event>, value: string) => {
        const target = event.currentTarget as HTMLInputElement; // Кастим currentTarget
        const name = target.name;
        localStorage.setItem('prelanglove1', value);
    };

    // Функция для валидации
    const validate = (value: string | undefined) => {
        if (forceValidate && !value) {
            return 'Выберите хотя бы один вариант'; // Сообщение об ошибке
        }
        return undefined;
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={{ validate }}
            render={
                ({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <div style={{border: '2px solid', borderColor: '#8768c8', borderRadius: '10px'}}>
                        <RadioGroup
                            row
                            aria-labelledby={`input-radio-${name}`}
                            name={"position" + name}
                            id={"input-radio-" + name}
                            defaultValue="top"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {options.map(option => {
                                return (
                                    <FormControlLabel
                                        key={option + name}
                                        value={option ?? ' '}
                                        sx={{
                                            color: 'white',
                                            borderRadius: '5px',
                                            backgroundColor: '#8768c8',
                                            margin: '5px',
                                            width: 'calc(50% - 10px)',
                                            boxSizing: 'border-box',
                                            maxWidth: '50%',
                                        }}
                                        label={option}
                                        labelPlacement="start"
                                        disabled={option === "Прикосновения"}
                                        onChange={(e: React.SyntheticEvent<Element, Event>) => {
                                            const target = e.currentTarget as HTMLInputElement; // Кастим currentTarget
                                            onChange(target.value); // Обработка значения
                                            updateSelection(e, option); // Передача значения в handler
                                        }}
                                        control={<Radio
                                            sx={{
                                                '&, &.Mui-checked': {
                                                    color: '#ffffff',
                                                },
                                            }}
                                            checked={value === option}
                                        />}
                                    />
                                );
                            })}
                        </RadioGroup>
                        </div>
                        {error && (
                            <FormHelperText error style={{textAlign: 'center', marginTop: '5px'}}>{error.message}</FormHelperText>
                        )}
                    </>
                )}
        />
    );
};

export default FormInputLangLoves;
