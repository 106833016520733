import { FormInputProps } from "../../types/formInputProps"
import FormInputDropdown from "./form-component/FormInputDropDown"

const BirthyearList = ({ name, control, setValue, forceValidate }: Omit<FormInputProps, 'label'>) => {
    const langLoveArr = [
        { value: 'Крыса' },
        { value: 'Бык' },
        { value: 'Тигр' },
        { value: 'Кролик' },
        { value: 'Дракон' },
        { value: 'Змея' },
        { value: 'Лошадь' },
        { value: 'Коза' },
        { value: 'Обезьяна' },
        { value: 'Петух' },
        { value: 'Собака' },
        { value: 'Кабан' },
        { value: 'Не знаю' },
    ]

    return(
        <FormInputDropdown
            forceValidate={forceValidate}
            name={name}
            control={control}
            setValue={setValue}
            label="Год какого животного вы родились?"
            options={langLoveArr}
        />
    )
}

export default BirthyearList;
