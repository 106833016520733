import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";
import { useMask } from 'react-mask-field';
import { FormInputProps } from "../../../types/formInputProps";


const FormInputReactMask = ({ name, control, rules, className }: Omit<FormInputProps, 'label'>) => {

    const ref = useMask({ mask: '_ (___) ___-__-__', replacement: { _: /\d/ } });

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            rules={{
                ...rules
            }}
            render={({ field }) => (
                // <InputMask
                //     mask="8 (999) 999-99-99"
                //     style={{
                //         width: '98%',
                //         height: '30px',
                //         border: '1px solid rgb(103 38 255)',
                //         color: "black",
                //     }}
                //     placeholder="Например, 89506142432"
                //     {...field}
                // >
                //     {/* {(inputProps: any) => (
                //         <input
                //             {...inputProps}
                //             type="text"
                //         />
                //     )} */}
                // </InputMask>

                <input  className={className} {...field}  ref={ref} style={{
                        maxWidth: 'calc(100% - 36px)',
                        width: '100%',
                        padding: '1em',
                        height: '30px',
                        border: '1px solid rgb(103 38 255)',
                        borderRadius: '5px',
                        color: "black",
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        //padding: "2px 0 2px 15px",
                    }}
                    placeholder="8 (___) ___-__-__"  />
            )}

        />
    );
};
export default FormInputReactMask
