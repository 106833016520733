import { Checkbox, FormControlLabel, FormGroup, Select } from "@mui/material";
import { pink } from "@mui/material/colors";
import React from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {
    options: string[]
}

const FormInputGroupButton: React.FC<CheBoxProps> = ({
    name,
    control,
    options
}) => {
    const generateSingleOptions = () => {
        return 
    };

    // const onChange = () =>{
    //     console.log('options', options)
    // }

    return (
        
            <Controller
                control={control}
                name={name}
                render={
                    ({ field: { onChange, value } }) => (
                        <FormGroup aria-label="position" row>
                                {options.map((option: string) => {
                                    return (
                                        <FormControlLabel
                                            key={option}
                                            value={option ?? ' '}
                                            control={< Checkbox />}
                                            sx={{
                                                border: '2px solid',
                                                borderRadius: '5px',
                                                borderColor: '#8768c8',
                                                margin: '5px',
                                                width: 'calc(50% - 10px)',
                                                textAlign: 'left'
                                            }}
                                            label={option}
                                            labelPlacement="start"
                                            onChange={onChange} 
                                            // value={value ?? ' '}
                                        />
                                        );
                                })}
                        </FormGroup>
                )}
                
            />
        
    );
};

export default FormInputGroupButton

    