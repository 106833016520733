import { FC, useCallback } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { useGetApiQuery } from "../../../services/goroskop";
import EnhancedTable from "./../constituents/Table";

const AdminSearchPassive : FC = () => {

    const { data, error, isLoading, currentData } = useGetApiQuery('profiles.php?search=passive')

    let newDate = data
    //  Ну я ниче лучше не придумал

    newDate = data?.filter(elem => elem.vip == '0')

    const MemoizedTable = useCallback(() =>
            <>
                {data && (
                    <>
                        <EnhancedTable data={newDate ? newDate : data} nameOfTable={'Пассивный поиск'} />
                    </>
                )}
            </>
        , [currentData])



    return(
        <>
            {error && <h1>oops, er: {error}</h1>}
            {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            <MemoizedTable />
        </>
    )
}



export default AdminSearchPassive