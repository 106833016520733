import { FC, useEffect} from "react";
import { getCookie } from "../../functions/Cookie";
import { useAppSelector } from "../../hooks/hooks";
import { useSubmitDataMutation } from "../../services/goroskop";
import EnhancedTable from "./constituents/Table";

const ModerationAdmin: FC = () => {

    const {  name, date } = useAppSelector(state => state.adminSlice)
    
    const [submitData, {data, error, isLoading, }] = useSubmitDataMutation()


    let newDate = date && data ? typeof data === 'string' ? JSON.parse(data) : true : false

    
    
    //  Ну я ниче лучше не придумал -mod_amd.tsx
    var role = getCookie('role'), newName: string;
    useEffect(()=> {
        let fd = new FormData()
        let route: string;
        if(newName === 'Модерация админа' && role == 'consideration'){
            route = 'ok_admin_consideration';
        }else{
            route = String(role);
        }
        console.log('useEffect mod_admin',{
            newName: newName,
            name: name,
            role: role,
            route: route
        });
        fd.append('role', route)

        submitData({ name: 'moderation.php', data: fd });

    }, [])

    if(
        (!name || typeof(name) == 'undefined') &&
        role == 'consideration'
    ){
        newName = 'Модерация админа';
    }else{
        newName = name;
    }

    return (
        <>
            {error && <h1>oops, error, log in console {console.error(error)}</h1>}
            {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            {data && <EnhancedTable data={newDate ? newDate : data} nameOfTable={newName} />}
        </>
    )
}

export default ModerationAdmin