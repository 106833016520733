import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import QuestionAnswer from "@mui/icons-material/QuestionAnswer";
import { TipsAndUpdates } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close'; // Иконка закрытия

interface DialogPasswordProps {
    numberVariant: number;
    code: string;
}

const DialogPassword: React.FC<DialogPasswordProps> = ({ numberVariant, code }) => {
    const [openPass, setOpenPass] = React.useState(false);
    const [error, setError] = React.useState(false); // Для отслеживания ошибки
    const [showIframe, setShowIframe] = React.useState(false); // Для управления видимостью iframe
    const [submitted, setSubmitted] = React.useState(false); // Для отслеживания состояния после отправки

    // Если код пустой, сразу показывать iframe
    React.useEffect(() => {
        console.log('code',code)
        if (code === '') {
            setShowIframe(true);
        }
    }, [code]);

    const handleClickOpenPass = () => {
        console.log('handleClickOpenPass')
        setOpenPass(true);
        if (code === '') {
            setShowIframe(true); // Показываем iframe сразу, если code пустой
            setSubmitted(true); // Устанавливаем состояние отправки
        } else {
            setShowIframe(false); // Иначе скрываем iframe
            setSubmitted(false); // Сбрасываем состояние отправки
        }
        setError(false); // Сброс ошибки при открытии диалога
    };

    const handleClosePass = () => {
        setOpenPass(false);
        setError(false); // Сброс ошибки при закрытии
        setShowIframe(false); // Скрыть iframe при закрытии
        setSubmitted(false); // Сбросить состояние
    };

    // Select icon and name based on the variant number
    const selectVariant = (variant: number) => {
        let id = 0;
        let icon = null;
        let name = null;
        let title = "";
        let description = "";
        let eventId = '';
        switch (variant) {
            case 1:
                id = 1;
                icon = <QuestionAnswer />;
                name = 'Подготовка'; // Оплатить консультацию
                title = "Введите код для Подготовки";
                description = "Пожалуйста, введите кодовую фразу, полученную во время общения с нашим менеджером.";
                eventId = '8261';
                break;
            case 2:
                id = 2;
                icon = <TipsAndUpdates />;
                name = 'AstroDating'; // Оплатить подписку
                title = "Введите код для AstroDating";
                description = "Пожалуйста, введите кодовую фразу, полученную во время общения с нашим менеджером.";
                eventId = '8260';
                break;
            default:
                id = 0;
                icon = null;
                name = 'Неизвестный вариант';
                title = "Неизвестная операция";
                description = "Операция не поддерживается.";
                eventId = '';
        }
        return { id, icon, name, title, description, eventId };
    };

    const { id, icon, name, title, description, eventId } = selectVariant(numberVariant);

    // Обработка отправки формы
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        const paycode = formJson.paycode;

        if (paycode === code) {
            setShowIframe(true); // Показываем iframe при правильном коде
            setError(false); // Сброс ошибки
            setSubmitted(true); // Отмечаем, что форма была успешно отправлена
        } else {
            setError(true); // Если код неправильный, устанавливаем ошибку
        }
    };

    return (
        <React.Fragment>
            <Button
                sx={{
                    borderRadius: '30px',
                    margin: '15px',
                    padding: '15px',
                    background: 'linear-gradient(90deg, rgba(5,181,205,1) 0%, rgba(2,210,210,1) 100%);'
                }}
                variant="contained"
                onClick={handleClickOpenPass}
                startIcon={icon}
            >
                {name}
            </Button>

            <Dialog
                open={openPass}
                onClose={handleClosePass}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                }}
                fullScreen={showIframe} // Диалог растягивается на весь экран, если показывается iframe
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 24px' }}>
                    <span>{!submitted ? title : (name) }</span>
                    <Button onClick={handleClosePass} sx={{ minWidth: 0, padding: 1 }}>
                        <CloseIcon sx={{color: '#05B5CD'}} />
                    </Button>
                </DialogTitle>
                <DialogContent sx={{
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                }}>
                    {!showIframe ? (
                        <>
                            <DialogContentText>{description}</DialogContentText>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="paycode"
                                name="paycode"
                                label="Кодовая фраза"
                                type="text"
                                fullWidth
                                variant="standard"
                                autoComplete="off"
                                error={error}
                                helperText={error ? "Неверный код." : ""}
                            />
                        </>
                    ) : (
                        <iframe
                            src={eventId !== '' ? `https://cbiletom.ru/event.php?event=${eventId}` : '#'}
                            title="Event"
                            style={{
                                marginTop: '0',
                                border: '2px solid #05B5CD',
                                borderRadius: '15px',
                                width: '100%',
                                height: 'calc(100vh - 110px)',
                                maxHeight: '100%',
                                backgroundColor: '#05B5CD',
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button sx={{color: '#05B5CD'}} onClick={handleClosePass}>Закрыть</Button>
                    {!submitted && !showIframe && <Button sx={{color: '#05B5CD'}} type="submit">Ввод</Button>}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DialogPassword;
