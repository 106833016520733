import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface QuizState {
    value: number;
    //email: number;
}

const initialState: QuizState = {
    value: 0,
    //email: Date.now() + Math.random()
}

const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        increment(state) {
            state.value += 1
        },
        decrement(state) {
            if (state.value > 0) {
                state.value -= 1
            }
        },
        setStep: (state, action) => {
            state.value = action.payload;
        },
        /*setEmail(state, action: PayloadAction<number>) {
            // '2022-02-10' || action.payload.split('-').reverse().join('.').split('.').pop()
            state.email = action.payload
        }*/
    }
})

export const { increment, decrement, setStep} = quizSlice.actions // , setEmail

export default quizSlice.reducer
