import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {
    option1: string | undefined,
    value1: string
}

const FormInputRadioButtonLabel: React.FC<CheBoxProps> = ({
    name,
    control,
    option1,
    value1,
    defaultValue,
}) => {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={
                ({ field: { onChange, value } }) => (
                    <RadioGroup
                        row
                        aria-labelledby={`input-radio-${name}`}
                        name={"position" + name}
                        id={"input-radio-" + name}
                        defaultValue={defaultValue}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        onChange={e => {
                            //console.log('onChange',e.target.value)
                            onChange(e.target.value)
                        }}
                    >
                        <FormControlLabel
                            key={value1 + name}
                            value={value1}
                            label={option1}
                            labelPlacement="end"
                            control={<Radio checked={value === value1} />}
                            sx={{
                                color: 'black'
                            }}
                        />
                    </RadioGroup>
                )}
        />
    );
};

export default FormInputRadioButtonLabel

