import { FC, useCallback } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { useGetApiQuery } from "../../../services/goroskop";
import EnhancedTable from "./../constituents/Table";

const AdminKorzina : FC = () => {
    console.log('AdminKorzina');
    const { data, error, isLoading, currentData } = useGetApiQuery('trash.php')
    let newDate = data
    const MemoizedTable = useCallback(() =>
            <>
                {data && (
                    <>
                        <EnhancedTable data={newDate ? newDate : data} nameOfTable={"Корзина"} />
                    </>
                )}
            </>
        , [currentData])
    return(
        <>
            {error && <h1>oops, er: {error}</h1>}
            {isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            <MemoizedTable />
        </>
    )
}



export default AdminKorzina