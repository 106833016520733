import React, { useState } from "react";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru' // import locale
dayjs.locale('ru') // use locale
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../../../datepicker.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ruRU } from '@mui/x-date-pickers/locales';
import { Controller, useFormContext } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";
//const Stack from '@mui/material/Stack';
//const DATE_FORMAT = "дд.мм.гггг";
import { DateValidationError } from '@mui/x-date-pickers/models';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const dateFormat = 'DD.MM.YYYY';
const yearAccess = 18;
const startOfQ12022 = dayjs('1900-01-01T00:00:00.000');
const nowOfQ12022 = dayjs();
const endOfQ12022 = nowOfQ12022.subtract(yearAccess, 'year');

const FormInputDateNEW = ({ name, control, label, sx, validate18 }: FormInputProps) => {
    var isBirthday = false;
    if(name === 'birthday'){
        isBirthday = true;
    }

    const [error, setError] = useState<DateValidationError | null>(null);

    const validate = (value: Dayjs | null): string | undefined => {
        if (!value) return undefined;

        let validationError: DateValidationError | null = null;

        if (isBirthday && (value.isAfter(endOfQ12022) || value.isBefore(startOfQ12022))) {
            validationError = 'maxDate';
        } else if (!isBirthday && (value.isAfter(nowOfQ12022) || value.isBefore(startOfQ12022))) {
            validationError = 'minDate';
        }

        setError(validationError);

        if(validate18) {
            switch (validationError) {
                case 'maxDate':
                case 'minDate': {
                    return `Регистрация разешена для людей от ${yearAccess} лет`;
                }
                default: {
                    return undefined;
                }
            }
        }
    };

    //const maxTimeRestrict = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    // const checkANdRestrictDate = (date: string) => {
    //     return date ? (date.includes('.') ? date.split('.').reverse().join('-') : date) : ''
    // }

    const [value, setValue] = React.useState<Dayjs | null>(dayjs());

    /*React.useEffect(() => {
        if (value) {
            console.log('newDate',value);
            // const { current: descriptionElement } = descriptionElementRef;
            // if (descriptionElement !== null) {
            //     descriptionElement.focus();
            // }
        }
    }, [value]);*/

    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={{ validate }}
                render={({ field: { onChange, value, ref }, fieldState: { error } }) => {

                    // console.log(field.value);

                    return (
                        <LocalizationProvider
                            adapterLocale="ru"
                            dateAdapter={AdapterDayjs}
                            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <DatePicker
                                slotProps={{
                                    inputAdornment: {
                                        position: 'end'
                                    },
                                    day: {
                                        sx: {
                                            '&:hover': {
                                                backgroundColor: '#02D2D2',
                                                transition: 'background-color 0.3s ease',
                                                color: '#fff'
                                            },
                                            '&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                                                backgroundColor: '#05B5CD'
                                            },
                                            '&.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#05B5CD',
                                                '&:hover': {
                                                    color: '#fff',
                                                    backgroundColor: '#05B5CD',
                                                    transition: 'background-color 0.3s ease',
                                                },
                                                '&:focus': {
                                                    color: '#fff',
                                                    backgroundColor: '#05B5CD',
                                                    transition: 'background-color 0.3s ease',
                                                },
                                            },
                                            '&.Mui-selected:focus': {
                                                backgroundColor: '#05B5CD',
                                                color: '#fff'
                                            },
                                            '&.Mui-selected:hover': {
                                                backgroundColor: '#05B5CD',
                                                color: '#fff'
                                            },


                                            '.MuiPickersMonth-monthButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#05B5CD',
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#05B5CD',
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:focus': {
                                                backgroundColor: '#02D2D2',
                                                color: '#fff'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:hover': {
                                                backgroundColor: '#02D2D2',
                                                color: '#fff'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:focus': {
                                                backgroundColor: '#05B5CD'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:hover': {
                                                backgroundColor: '#05B5CD'
                                            },
                                        }
                                    },
                                    desktopPaper: {
                                        sx: {
                                            '.MuiPickersMonth-monthButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#05B5CD',
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#05B5CD',
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:focus': {
                                                backgroundColor: '#02D2D2',
                                                color: '#fff'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:hover': {
                                                backgroundColor: '#02D2D2',
                                                color: '#fff'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:focus': {
                                                backgroundColor: '#05B5CD'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton.Mui-selected:hover': {
                                                backgroundColor: '#05B5CD'
                                            },
                                        },
                                    },
                                    mobilePaper: {
                                        sx: {
                                            '.MuiPickersMonth-monthButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#05B5CD',
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#05B5CD',
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:focus': {
                                                backgroundColor: '#02D2D2'
                                            },
                                            '.MuiPickersYear-root > .MuiPickersYear-yearButton:hover': {
                                                backgroundColor: '#02D2D2'
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected:focus': {
                                                backgroundColor: '#05B5CD'
                                            },
                                            '.MuiPickersYear-yearButton.Mui-selected:hover': {
                                                backgroundColor: '#05B5CD'
                                            },
                                        },
                                    },
                                    textField: {
                                        helperText: error?.message,
                                    },
                                }}
                                onError={(newError) => setError(newError)}
                                minDate={startOfQ12022}
                                maxDate={isBirthday ? endOfQ12022 : nowOfQ12022}
                                label={label}
                                onChange={onChange}
                                onAccept={onChange}
                                value={value}
                                inputRef={ref}
                            />
                        </LocalizationProvider>
                    )}
                }
            />
        </>
    );
};

export default FormInputDateNEW;
