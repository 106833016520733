import { useEffect, useState } from "react";
import {getCookie, setCookie} from "../functions/Cookie";
import { useGetApiQuery } from "../services/goroskop";
import { Data } from "../types/data";
import { useAppSelector } from "./hooks";


export default function useWhoIs(): { Account: Data, whoIs: 'admin' | 'user' | "ok_dok" | "ok_mainDok" | 'unlogined' | 'ok_admin' | 'ok_user' } {
    const { data, isSuccess } = useGetApiQuery('auth.php')
    const { easyRole, Account } = useAppSelector(state => state.main)

    const role = getCookie('role')

    const [whoIs, setwhoIs] = useState({ Account: {} as Data, whoIs: switchRole(role) })

    function switchRole(role: any): 'admin' | 'user' | 'unlogined' {
        switch (role) {
            case "ok_admin":
            case "ok_mainModer":
            case "ok_Moder":
            case "ok_mainDok":
            case "ok_dok":
            case "consideration":
                return 'admin'
                break;
            case "ok_user":
                return 'user'
                break;
            case null:
                return 'unlogined'
                break;
            default:
                return 'unlogined'
                break;
        }
    }

    try {
        if (isSuccess && data && data[0].role && (whoIs.whoIs !== switchRole(data[0].role) || whoIs.Account !== data[0])) {
            if(data[0] && data[0]['tariff'] && typeof(data[0]['tariff']) !== "undefined"){
                //@ts-ignore
                let tariff: number | string = parseInt(data[0]['tariff'])
                if(tariff > 0) {
                    //@ts-ignore
                    tariff = tariff.toString();
                    setCookie('tariff', tariff, 7);
                }
            }
            setwhoIs({Account: data[0], whoIs: switchRole(data[0].role)})
        }
    
        Account && whoIs.whoIs !== easyRole && setwhoIs({ Account: Account, whoIs: easyRole })
    }catch (e){

    }
    return whoIs

}

