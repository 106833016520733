// services/messageService.ts
import axios from "axios";
import {getCookie} from "../functions/Cookie";

export const getUnreadMessagesCount = async (): Promise<number> => {
    /*try {
        const apiChat = '/api/im';
        const token = getCookie('token');
        const data = await axios.get(
            `${apiChat}/messages/unread`,
            { headers: { Authorization: `Bearer ${token}` }}
        );
        return data.data.count;  // Ожидаем, что сервер вернет объект с полем "count"
    } catch (error) {
        console.error('Error fetching unread messages count:', error);
        return 0;  // Если произошла ошибка, считаем, что сообщений нет
    }*/
    return 0;
};