import * as React from 'react';
import {FC} from "react";
import {useGetLogQuery} from "../../services/goroskop";
import {logData} from "../../types/data";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Actionlog: FC = () => {
    const {data, error, isLoading} = useGetLogQuery('actions_log.php')

    const printHtml = (htmlData: string) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlData }} />
        );
    }

    const printLogArr = (arr: logData[]) => {
        return arr.map((row) => (
            <StyledTableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell sx={{whiteSpace: "nowrap"}} component="th" scope="row">
                    {row.id}
                </StyledTableCell>
                <StyledTableCell align="center">{row.uid}</StyledTableCell>
                <StyledTableCell align="center">{row.who}</StyledTableCell>
                <StyledTableCell align="center">{printHtml(row.event)}</StyledTableCell>
                <StyledTableCell align="center">{row.datatime}</StyledTableCell>
            </StyledTableRow>
        ));
    }
    const printLogData = (Data: logData[] | undefined) => {
        if(Data) {
            return (
                <TableContainer component={Paper}>
                    <h1>Лог последних действий</h1>
                    <Table sx={{ minWidth: 650, width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>ID события</StyledTableCell>
                                <StyledTableCell align="center">ID пользователь</StyledTableCell>
                                <StyledTableCell align="center">Имя пользователя</StyledTableCell>
                                <StyledTableCell align="center">Событие</StyledTableCell>
                                <StyledTableCell align="center">Дата</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {printLogArr(Data)}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        return null;
    }

    return (
        <>
            {isLoading && <h1>Загрузка...</h1>}
            {data && printLogData(data)}
        </>
    );
}

export default Actionlog