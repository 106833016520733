import { Button, Card, CardActionArea, CardContent, CardMedia, styled, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { FC, useState, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import {useGetApiQuery, useSubmitDataWithRerenderMutation} from "../../services/goroskop"
import { DokType } from "../../types/dokConfig"
import FormInputDate from "../quiz/form-component/FormInputDate"
import FormInputDropdown from "../quiz/form-component/FormInputDropDown"
import FormInputText from "../quiz/form-component/FormInputText"
import T from "../quiz/OnlyText"
import {getCookie} from "../../functions/Cookie";

const Form = styled('form')({
    '& > div': {
        border: '1px solid rgb(80, 61, 122)'
    },
    '& > div > label.MuiFormLabel-filled': {
        display: 'none'
    },
    '& > div > label.css-u9osun': {
        display: 'none'
    },
    '& > div > label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        display: 'none'
    },
});

const Input = styled('input')({
    display: 'none',
});

const configLineThree: FC = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [submitData, { data, error, isError, isSuccess }] = useSubmitDataWithRerenderMutation()
    const dokconfig = useGetApiQuery('config_dok.php?get=yes')
    const { register, handleSubmit, watch, formState: { errors }, control, setValue } = useForm<DokType>({
        defaultValues: {
            dateFrom: '',
            dateUntil: '',
            gender: 'Ж'
        }
    });

    const onSubmit: SubmitHandler<DokType> = data => {

        //console.log('data', data)
        // @ts-ignore
        // if(typeof(data.status) === "boolean"){
        //     // @ts-ignore
        //     enqueueSnackbar(data.msg, {variant: (data.status === true ? 'success' : 'error') });
        //     console.log('data', data)
        // }

        let newData = data;

        let fd = new FormData()
        fd.append('data', JSON.stringify(newData))
        fd.append('name', 'dok')
        //console.log(fd.get('data'));

        submitData({ name: 'config_dok.php', data: fd })
            .then(response => {
                //console.log('response',response)
                // @ts-ignore
                let res = response.data;
                if (res && typeof(res.status) === 'boolean') {
                    if(res.msg && res.msg.length > 0) {
                        enqueueSnackbar(res.msg, {variant: (res.status === true ? 'success' : 'error')});
                    }else{
                        enqueueSnackbar('Ошибка передачи данных', {variant: 'error'});
                    }
                }
            })
        //console.log(data.o_img1)
    };


    const role = getCookie('role');

    const printFormArea = (data: any) => {
        console.log('area',data);
        if(data[0].status) {
            // defaultValues.dateFrom
            // defaultValues.dateUntil
            // defaultValues.gender
            return (
                <Form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'left'}}>

                    <T>Начальная дата ({data[0].data.dateFrom})</T>
                    <FormInputDate
                        control={control}
                        name="dateFrom"
                        defaultValue={data[0].data.dateFrom ? data[0].data.dateFrom : ''}
                    />

                    <T>Конечная дата ({data[0].data.dateUntil})</T>
                    <FormInputDate
                        control={control}
                        name="dateUntil"
                        defaultValue={data[0].data.dateUntil ? data[0].data.dateUntil : ''}
                    />

                    <T>Пол ({data[0].data.gender})</T>
                    <FormInputDropdown
                        control={control}
                        name='gender'
                        label="Выберите пол"
                        options={[{value: 'М'}, {value: 'Ж'}]}
                        defaultValue={data[0].data.gender ? data[0].data.gender : ''}
                    />

                    <hr/>
                    <T>По текщим настройкам выводится: <strong>{data[0].data.count}</strong> людей</T>
                    <hr/>
                    <Button type="submit" variant="contained">Сохранить</Button>
                </Form>

            );
        }else{
            if(data[0].msg){
                enqueueSnackbar(data[0].msg, {
                    variant: 'error',
                })
                return (<>{data[0].msg}</>);
            }else {
                enqueueSnackbar('Упс... Ошибка на сервере', {
                    variant: 'error',
                })
            }
        }
        return (<>Ошибка1</>);
    }


    return (
        <Typography component="div" style={{textAlign: 'left', maxWidth: '450px'}}>
            <h1>Настройка работы линии 3</h1>
            {dokconfig.isError && <h1>Ошибка</h1>}
            {dokconfig.isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            {dokconfig.isSuccess && printFormArea(dokconfig.data)}
        </Typography>
    )
}

export default configLineThree
