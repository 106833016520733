import regionCodes from "../components/forAll/regions";

export const printRegionName = (regionId: string) => {
    let regionName = regionId;
    try {
        for (let region of regionCodes) {
            if (regionId === region.value) {
                regionName = region.value + ' - ' + region.label;
                break;
            }
        }
    }catch (e){}
    return regionName;
}